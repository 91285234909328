import { googleLogout } from "@react-oauth/google";
import axios from "axios";
import { UserAgentApplication } from "msal";
import { Button } from "primereact/button";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";
import {
  useRefreshTokenStore,
  useStoreEntityIdFromUrl,
  useTokenStore,
} from "../../store";
import useImpersonating from "../../hooks/useImpersonating";
function JoinEntity() {
  // const { entityJoined } = useJoinRequest();
  const navigate = useNavigate();
  const { isSystemAdminImpersonating } = useImpersonating();
  const { token, setToken } = useTokenStore();
  const { refreshToken: currentRefreshToken, setRefreshToken } =
    useRefreshTokenStore();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const location = useLocation();
  const isAdmin = location.pathname.includes("admin");

  const msalConfig = {
    auth: {
      clientId: "736fdbcc-003f-4597-a7e9-9ef7947c56c9",
      authority:
        "https://login.microsoftonline.com/33cb929f-07b5-4671-a6e0-314ef40bb333",
      redirectUri: "https://localhost:44413/login",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  };

  const msalInstance = new UserAgentApplication(msalConfig);

  const microsoft = () => {
    // msalInstance.logout();
    sessionStorage.clear();
  };

  const logOut = async () => {
    try {
      await axios.post(
        "/Authentication/LogOut",
        { refreshToken: currentRefreshToken },
        {
          headers: { Authorization: `Bearer ${token}` },
          "Content-Type": "application/json",
        },
      );
    } catch (err) {}
    microsoft();
    setToken(null);
    setRefreshToken(null);
    setEntityId(null);

    googleLogout();
    navigate(
      isAdmin || isSystemAdminImpersonating ? "/admin/login" : "/login",
      {
        replace: true,
      },
    );
  };
  const entityJoined = location.state?.entityJoined;
  return (
    <>
      <div className="flex h-screen items-center justify-center ">
        <div className="Xl:w-2/5 mt-10 rounded-3xl bg-light-text p-10  py-8 sm:w-4/5  md:w-3/5 lg:w-2/5 2xl:w-2/5">
          <div className="m-auto flex h-36 w-36 items-center justify-center overflow-hidden rounded-full bg-slate-100">
            <MedicalHistoryIcon />
          </div>
          <div className="mt-3 text-center">
            <h4 className="font-Poppins font-medium  capitalize text-dark-purple">
              You’ve requested to join
            </h4>
            <h4 className="font-Poppins font-medium  capitalize text-dark-purple">
              {" "}
              {entityJoined.entityName}{" "}
            </h4>
            <p className="mt-5 text-p">
              Please consider that requests may take time, You will receive an
              email to inform you whether your request has been approved or
              declined. To contact our support, please use the following
              information:
            </p>
            <p className="mt-4 capitalize">
              <span className="text-dark-purple">technical support name :</span>{" "}
              {entityJoined.technicalSupportName}
            </p>
            <p className="mt-2">
              <span>
                <i className="pi pi-phone pr-1 text-dark-purple"></i>{" "}
                {entityJoined.technicalSupportPhoneNumber}{" "}
              </span>
              <span>
                <i className="pi pi-envelope pl-4 text-dark-purple"></i>{" "}
                {entityJoined.technicalSupportEmail}{" "}
              </span>
            </p>
          </div>
          {!isSystemAdminImpersonating && (
            <div className="mt-2">
              <Button
                link
                className="capitalize"
                onClick={() => {
                  logOut();
                }}
              >
                log out
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default JoinEntity;
