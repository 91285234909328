import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputNumber } from "primereact/inputnumber";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import useHandleResize from "../../../hooks/useHandleResize";
import { RadioButton } from "primereact/radiobutton";
import { blockSpecialCharsPattern, trimValidation } from "../../utility/Utils";
import ReusableCardList from "../../ReusableCardList/ReusableCardList";
import ReusableFilter from "../../ReusableFilter/ReusableFilter";

export const CreateBillableItem = ({
  data,
  refetch,
  setRefetch,
  setVisible,
  toast,
  addToService,
  setAddedBillableItemForService,
  selectedCptCodes,
  setSelectedCptCodes,
  hcpcsSearchValue,
  handleBeforeSubmit,
  collapse = true
}) => {
  const [searchValue, setSearchValue] = useState(hcpcsSearchValue || "");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [delayTimer, setDelayTimer] = useState(null);
  const [selectedCPT, setSelectedCPT] = useState(data ? data.cptCode : null);
  const [CPTs, setCPTs] = useState();
  const [CPTCount, setCPTCount] = useState();
  const { entityId } = useTokenData();
  const { http } = useAxios();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [collapsed, setCollapsed] = useState(collapse);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { isMobile } = useHandleResize();
  const dialogText = useRef("");
  const [showHCPCS, setShowHCPCS] = useState(true);

  const defaultValues = {
    hcpcs: "",
    shortDescription: "",
    longDescription: "",
    description: "",
    mod1: "",
    mod2: "",
    mod3: "",
    mod4: "",
    attributeType: "",
    attributeTypeValue: "",
    chargeAmount: 0,
    defaultUnits: 1,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    control,
  } = useForm({ defaultValues });
  const attributeTypes = [
    { name: "None", code: 1 },
    { name: "NDC", code: 2 },
    { name: "Supply Id", code: 3 },
    { name: "Provider Type", code: 4 },
  ];
  const [selectedAttr, setSelectedAttr] = useState(
    data
      ? attributeTypes.filter((attr) => attr.code === data.attributeType)[0]
      : { name: "None", code: 1 },
  );
  const validateAttributeTypeValue = (value) => {
    if (selectedAttr.code === 1 && !value) {
      return null;
    } else if (!value) {
      return "Attribute type value is required";
    } else if (!value.trim()) {
      return "Attribute type value can not be empty";
    }
  };
  const onPageChange = (event) => {
    if (first === event.first) return;
    setFirst(event.first);
    setRows(event.rows);
    getCPTCodes(undefined, event.page + 1);
  };

  useEffect(() => {
    getCPTCodes();
  }, []);

  const getCPTCodes = async (text, page) => {
    setIsLoading(true);
    let response;
    if (text !== undefined) {
      response = await http.get(
        `/CptCodes?Filters=${text || ""}&Page=${page || 1}&PageSize=5`,
      );
    } else {
      response = await http.get(
        `/CptCodes?Filters=${searchValue || ""}&Page=${page || 1}&PageSize=5`,
      );
    }

    setCPTs(response?.data);
    setCPTCount(response?.data[0]?.count);
    setIsLoading(false);
  };

  const tableHeaderTemplate = (
    <div className="flex justify-end ">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search"
          value={searchValue}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          keyfilter={blockSpecialCharsPattern}
          onChange={(e) => {
            clearTimeout(delayTimer);
            setSearchValue(e.target.value);
            setDelayTimer(
              setTimeout(() => {
                setFirst(0);
                getCPTCodes(e.target.value);
              }, 700),
            );
          }}
        />
      </span>
    </div>
  );
  const panelHeaderTemplate = (
    <div className="flex items-center justify-end">
      <label className="collapsable cursor-pointer" id="billable-collapsable">
        Import Default Values from National HCPCS Code
      </label>
    </div>
  );
  const handleRowClick = (rowData) => {
    if (selectedCPT && selectedCPT.id === rowData.id) {
      setSelectedCPT(null);
    } else {
      setSelectedCPT(rowData);
    }
  };
  const refToShortDescription = useRef(null);

  const submit = async (formData) => {
    setIsSaving(true);
    const updatedData = {
      ...formData,
      attributeType: selectedAttr.code,
      entityId: entityId,
      cptCodeId: selectedCPT
        ? selectedCPT.id
        : "00000000-0000-0000-0000-000000000000",
      hcpcs: selectedCPT ? selectedCPT.cptCode : formData.hcpcs,
      chargeAmount: +formData.chargeAmount,
      // shortDescription: data.friendlyShort,
    };
    let newUpdatedData;
    try {
      if (data) {
        await http.put(`/BillableItems/${data.id}`, updatedData);
      } else {
        newUpdatedData = await http.post(`/BillableItems`, updatedData);
      }
      if (addToService) {
        const cloneSelected = structuredClone([...selectedCptCodes]);
        const index = selectedCptCodes.findIndex(
          (obj) => obj.id === newUpdatedData.data.id,
        );
        // If the object exists, remove it; otherwise, add it to the array
        if (index !== -1) {
          // Remove the object from the array using splice
          cloneSelected.splice(index, 1);
        } else {
          // Add the object to the selectedCptCodes
          cloneSelected.push({
            ...newUpdatedData.data,
            units: newUpdatedData.data.defaultUnits,
          });
        }
        setSelectedCptCodes(cloneSelected);
      }
      setVisible(false);

      refetch !== undefined && setRefetch(!refetch);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: `Item ${data ? "Edited" : handleBeforeSubmit ? "Replaced" : "Added"} Successfully`,
        life: 4000,
      });
    } catch (error) {
      const errorText =
        error.response.data.message ||
        "Something went wrong. Please try again later";

      if (errorText.includes("maximum allowable charge")) {
        dialogText.current = errorText.replace(/className=/g, "class=");
        setDialogVisible(true);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorText,
          life: 4000,
        });
      }
    }
    setIsSaving(false);
  };
  const onSubmit = async (formData) => {
    if (handleBeforeSubmit) {
      handleBeforeSubmit(submit, formData);
    } else {
      await submit(formData);
    }
  };

  useEffect(() => {
    if (selectedCPT) {
      setValue("shortDescription", selectedCPT.friendlyShort);
      setValue("longDescription", selectedCPT.friendlyFull);
      setValue("description", selectedCPT.description);
      setValue("hcpcs", selectedCPT.cptCode);
      setError("shortDescription", undefined);
      setError("longDescription", undefined);
      setError("description", undefined);
      setError("hcpcs", undefined);
    }
    return () => {
      setValue("shortDescription", "");
      setValue("longDescription", "");
      setValue("description", "");
      setValue("hcpcs", "");
    };
  }, [selectedCPT]);

  useEffect(() => {
    if (!data) return;
    data ? setShowHCPCS(false) : setShowHCPCS(true);
    reset(data);
  }, []);

  const cardTemplate = (row) => {
    return (
      <div className="flex flex-col gap-2 rounded-lg border p-4 text-sm">
        <div className="flex gap-2">
          <RadioButton
            inputId={row.cptCode}
            name={row.cptCode}
            value={row}
            onChange={(e) => {
              setSelectedCPT(e.value);
              refToShortDescription.current?.scrollIntoView({
                behavior: "smooth",
              });
            }}
            checked={row.cptCode === selectedCPT}
          />
          <label htmlFor={row.cptCode} className="">
            <p>
              <span className="text-slate-500">Code:&nbsp;</span>
              <span className="text-black">{row.cptCode}</span>
            </p>
          </label>
        </div>
        <p>
          <span className="text-slate-500">Description:&nbsp;</span>
          <span className="text-black">{row.description}</span>
        </p>
        <p>
          <span className="text-slate-500">Friendly Full:&nbsp;</span>
          <span className="text-black">{row.friendlyFull}</span>
        </p>
        <p>
          <span className="text-slate-500">Friendly Short:&nbsp;</span>
          <span className="text-black">{row.friendlyShort}</span>
        </p>
      </div>
    );
  };

  const validateChargeAmount = (value) => {
    if (isNaN(value) || value == 0) {
      return "Charge amount must be a positive number";
    }
    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      return "Enter a valid number with up to 2 decimal places.";
    }

    return true;
  };

  return (
    <>
      <Tooltip target=".custom-target-icon" />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        {showHCPCS && (
          <div className="my-4 rounded-lg">
            <Panel
              expandIcon={
                <i
                  className="pi pi-angle-down collapsable"
                  style={{ fontSize: "1.3rem", marginTop: "5px" }}
                ></i>
              }
              collapseIcon={
                <i
                  className="pi pi-angle-up collapsable"
                  style={{ fontSize: "1.3rem", marginTop: "5px" }}
                ></i>
              }
              header={panelHeaderTemplate}
              className={`billable-header ${!collapsed ? "billable-header-expanded" : null}`}
              toggleable
              collapsed={collapsed}
              onClick={(e) => {
                // Check if the element has the class "p-panel-header" or contains "collapsable" in its class list
                if (
                  e.target.className === "p-panel-header" ||
                  e.target.className.includes("collapsable")
                ) {
                  setCollapsed(!collapsed);
                } else {
                  setCollapsed(collapsed);
                }
              }}
              onToggle={() => setCollapsed(!collapsed)}
            >
              <div className="rounded-t-lg">
                {!collapsed &&
                  (isMobile ? (
                    <>
                      <ReusableFilter
                        keyfilter={blockSpecialCharsPattern}
                        searchValue={searchValue}
                        onSearch={[
                          (e) => e.key === "Enter" && e.preventDefault(),
                        ]}
                        sendSearchData={(e) => {
                          clearTimeout(delayTimer);
                          setSearchValue(e.target.value);
                          setDelayTimer(
                            setTimeout(() => {
                              setFirst(0);
                              getCPTCodes(e.target.value);
                            }, 500),
                          );
                        }}
                      />
                      <ReusableCardList
                        tableData={CPTs}
                        actionTemplates={[{ template: cardTemplate }]}
                      />
                    </>
                  ) : (
                    <DataTable
                      value={CPTs}
                      header={tableHeaderTemplate}
                      selectionMode="single"
                      selection={selectedCPT}
                      onSelectionChange={(e) => {
                        setSelectedCPT(e.value);
                        refToShortDescription.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                      pt={{
                        loadingOverlay: {
                          style: {
                            backgroundColor: "#C6C1FF40",
                          },
                        },
                      }}
                      loading={isLoading}
                      onRowClick={(e) => handleRowClick(e.data)}
                    >
                      <Column header="Billable item code" field="cptCode" />
                      <Column header="Description" field="description" />
                      <Column header="Friendly Full" field="friendlyFull" />
                      <Column header="Friendly Short" field="friendlyShort" />
                    </DataTable>
                  ))}
                {!collapsed && CPTCount > 0 && (
                  <div className="border-y-2">
                    <Paginator
                      className="rounded-t-none"
                      first={first}
                      rows={rows}
                      totalRecords={CPTCount}
                      onPageChange={onPageChange}
                      template={{
                        layout: isMobile
                          ? "PrevPageLink CurrentPageReport NextPageLink"
                          : "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                      }}
                    />
                  </div>
                )}
              </div>
            </Panel>
          </div>
        )}

        {selectedCPT && (
          <div className="block divide-y-8 divide-transparent border-b p-2 text-sm sm:hidden">
            <p>Selected CPT Code:</p>
            <div>
              <p>Code: {selectedCPT.cptCode}</p>
              <p>Description: {selectedCPT.friendlyShort}</p>
            </div>
            <Button
              label="Clear Selection"
              type="button"
              icon="pi pi-times"
              text
              onClick={() => {
                setSelectedCPT(null);
              }}
              className="p-0"
            />
          </div>
        )}

        <div className="flex flex-col gap-2 rounded-lg border border-slate-200 p-4">
          {/* HCPCS */}
          <div className="space-y-1">
            <label htmlFor="hcpcs" className="block">
              HCPCS
              <span className="ml-1 font-bold text-red-500">*</span>
            </label>
            <div className="flex justify-between">
              <div className="basis-full sm:basis-3/5">
                <Controller
                  control={control}
                  name="hcpcs"
                  render={({ field }) => (
                    <InputText
                      id="hcpcs"
                      className="mb-2 w-full pb-2 pt-2"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        clearTimeout(delayTimer);
                        setDelayTimer(
                          setTimeout(() => {
                            setFirst(0);
                            getCPTCodes(e.target.value);
                          }, 500),
                        );
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      onBlur={field.onBlur}
                      disabled={selectedCPT ? true : false}
                    />
                  )}
                  rules={{
                    required: "HCPCS is required",
                    validate: (value) => trimValidation(value, "HCPCS"),
                  }}
                />

                {errors.hcpcs && (
                  <p className="mt-1 text-red-500">{errors.hcpcs.message}</p>
                )}
              </div>
              <div className="hidden items-center sm:flex">
                <span className=" text-red-600">
                  <Button
                    label="Clear Selection"
                    type="button"
                    outlined
                    raised
                    onClick={() => {
                      setSelectedCPT(null);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>

          {/* Short Description */}
          <div className="space-y-1">
            <label htmlFor="shortDescription" ref={refToShortDescription}>
              Friendly Short Description
              <span className="ml-1 font-bold text-red-500">*</span>
            </label>
            <InputTextarea
              id="shortDescription"
              className="w-full"
              {...register("shortDescription", {
                required: "Short description is required",
                validate: (value) => trimValidation(value, "Short description"),
              })}
            />
            {errors.shortDescription && (
              <p className="mt-1 text-red-500">
                {errors.shortDescription.message}
              </p>
            )}
          </div>

          {/* Long Description */}
          <div className="space-y-1">
            <div>
              <label htmlFor="longDescription">Friendly Full Description</label>
            </div>
            <InputTextarea
              id="longDescription"
              className="w-full"
              {...register("longDescription", {})}
            />
          </div>

          {/*  Description */}
          <div className="space-y-1">
            <label htmlFor="description" ref={refToShortDescription}>
              Description
            </label>
            <span className="ml-1 font-bold text-red-500">*</span>
            <InputTextarea
              id="description"
              className="w-full"
              {...register("description", {
                required: " description is required",
              })}
            />
            {errors.description && (
              <p className="mt-1 text-red-500">{errors.description.message}</p>
            )}
          </div>

          {/* Mods */}
          <div className="flex flex-wrap justify-between gap-4 sm:justify-start">
            <div className="basis-5/12 space-y-1 sm:basis-1/5">
              <div>
                <label htmlFor="mod1">Modifier 1</label>
              </div>
              <InputText
                id="mod1"
                className="w-full pb-2 pt-2"
                {...register("mod1")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="basis-5/12 space-y-1 sm:basis-1/5">
              <div>
                <label htmlFor="mod2">Modifier 2</label>
              </div>
              <InputText
                id="mod2"
                className="w-full pb-2 pt-2"
                {...register("mod2")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="basis-5/12 space-y-1 sm:basis-1/5">
              <div>
                <label htmlFor="mod3">Modifier 3</label>
              </div>
              <InputText
                id="mod3"
                className="w-full pb-2 pt-2"
                {...register("mod3")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="basis-5/12 space-y-1 sm:basis-1/5">
              <div>
                <label htmlFor="mod4">Modifier 4</label>
              </div>
              <InputText
                id="mod4"
                className="w-full pb-2 pt-2"
                {...register("mod4")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>

          {/* Attribute Type And Value */}
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="space-y-1">
              <div>
                <label htmlFor="attributeType">Attribute Type</label>
              </div>
              <Dropdown
                id="attributeType"
                className="w-full sm:w-auto"
                value={selectedAttr}
                options={attributeTypes}
                placeholder="Select Attribute Type"
                optionLabel="name"
                {...register("attributeType")}
                onChange={(e) => {
                  setSelectedAttr(e.value);
                }}
              />
            </div>
            <div className="space-y-1">
              <div>
                <label htmlFor="attributeTypeValue">Attribute Type Value</label>
                {selectedAttr.code === 1 ? null : (
                  <span className="ml-1 font-bold text-red-500">*</span>
                )}
              </div>
              <InputText
                id="attributeTypeValue"
                className="h-[3em] w-full pb-2 pt-2"
                {...register("attributeTypeValue", {
                  validate: validateAttributeTypeValue,
                })}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
              {errors.attributeTypeValue && (
                <p className="mt-1 text-red-500">
                  {errors.attributeTypeValue.message}
                </p>
              )}
            </div>
          </div>

          {/* Charge Amount & Default Units */}
          <div className="mt-2 flex flex-col gap-4 sm:flex-row">
            <div className="space-y-1">
              <div>
                <label htmlFor="chargeAmount">Price</label>
                <span className="ml-1 font-bold text-red-500">*</span>
                <span className="pl-3 text-gray-500">
                  <i
                    className="custom-target-icon pi pi-info-circle text-lg"
                    data-pr-tooltip="The amount you expect to receive when this service is ordered"
                    data-pr-position="right"
                    style={{ cursor: "pointer" }}
                  ></i>
                </span>
              </div>
              <Controller
                name="chargeAmount"
                control={control}
                rules={{
                  required: "Charge amount is required",
                  validate: validateChargeAmount,
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    inputId="chargeAmount"
                    value={field.value}
                    id={field.name}
                    inputRef={field.ref}
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    mode="currency"
                    currency="USD"
                    min={0}
                    locale="en-US"
                    allowEmpty={false}
                    className={`h-[3em] w-full  ${fieldState.error ? "p-invalid" : ""}`}
                  />
                )}
              />
              {errors.chargeAmount && (
                <p className="mt-1 text-red-500">
                  {errors.chargeAmount.message}
                </p>
              )}
            </div>
            <div className="space-y-1">
              <div>
                <label htmlFor="defaultUnits">Default Unit</label>
              </div>
              <InputText
                id="defaultUnits"
                min={1}
                keyfilter={/^\d+$/}
                className="w-full"
                {...register("defaultUnits", {
                  required: "Default Units is required",
                  min: {
                    value: 1,
                    message: "Default Units must be greater than 0",
                  },
                })}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
              {errors.defaultUnits && (
                <p className="mt-1 text-red-500">
                  {errors.defaultUnits.message}
                </p>
              )}
            </div>
          </div>

          {/* Selected CPT */}
          {selectedCPT && (
            <p className="mb-2 mt-4 font-bold ">Selected HCPCS:-</p>
          )}
          {selectedCPT && (
            <div className="ml-2 flex gap-8">
              <p>
                Code: <span className="font-bold">{selectedCPT.cptCode}</span>
              </p>
              <p>
                Description:{" "}
                <span className="font-bold">{selectedCPT.description}</span>
              </p>
            </div>
          )}
          {/* CPT Code Table */}

          <Button
            label={data ? "Update" : "Save"}
            loading={isSaving}
            className="mt-4 w-full self-center sm:w-max sm:self-end"
            type="submit"
            outlined
            raised
          />
          <Dialog
            header={
              <div className="flex items-center rounded-t-lg p-2 text-red-500">
                <i
                  className="pi pi-exclamation-triangle text-lg font-semibold text-red-500"
                  style={{ marginRight: "12px", marginLeft: "5px" }}
                ></i>
                <span className="text-lg font-bold">Charge Limit Exceeded</span>
              </div>
            }
            draggable={false}
            visible={dialogVisible}
            style={{ width: "40vw" }}
            onHide={() => {
              setDialogVisible(false);
            }}
          >
            <p
              className="m-0 rounded-md border border-solid border-red-500 px-5 py-7"
              dangerouslySetInnerHTML={{ __html: dialogText.current }}
            />
          </Dialog>
        </div>
      </form>
    </>
  );
};
