export const calculateAge = (dateOfBirth) => {
  // Split the date string into year, month, and day components
  const dobArray = dateOfBirth.split("-");
  const dobYear = parseInt(dobArray[0]);
  const dobMonth = parseInt(dobArray[1]) - 1; // Months are 0-indexed in JavaScript
  const dobDay = parseInt(dobArray[2]);
  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in years
  let age = currentDate.getFullYear() - dobYear;

  // Check if the current date hasn't reached the birth month/day yet
  if (
    currentDate.getMonth() < dobMonth ||
    (currentDate.getMonth() === dobMonth && currentDate.getDate() < dobDay)
  ) {
    age--;
  }

  return age;
};

export const isNumberEqualCondition = (num, condition) => {
  if (condition.numberComparisionCondition === "equal") {
    return +num === +condition.numberComparision;
  }
  if (condition.numberComparisionCondition === "biggerNumber") {
    return +num > +condition.numberComparision;
  }
  if (condition.numberComparisionCondition === "smallerNumber") {
    return +num < +condition.numberComparision;
  }
  if (condition.numberComparisionCondition === "biggerThanOrEqual") {
    return +num >= +condition.numberComparision;
  }
  if (condition.numberComparisionCondition === "smallerThanOrEqual") {
    return +num <= +condition.numberComparision;
  }
  if (condition.numberComparisionCondition === "between") {
    return (
      +num >= +condition.numberComparisionRange.min &&
      +num <= +condition.numberComparisionRange.max
    );
  }
};

const matchedQuestionsBasedOnAgeAndGender = ({
  patientInfo,
  currentQuestion,
}) => {
  if (!currentQuestion.show) {
    return true;
  }
  const { action } = currentQuestion;
  let isMatched = true;

  for (let i = 0; i < currentQuestion.conditions.length; i++) {
    const condition = currentQuestion.conditions[i];
    const { question, answer, relation } = condition;
    const questionLabel = question.label.label
      ? question.label.label
      : question.label;

    let userAnswer;

    if (questionLabel === "Gender") {
      userAnswer = [patientInfo.gender];
    } else if (questionLabel === "Age") {
      userAnswer = calculateAge(patientInfo.birth);
    } else {
      return;
    }

    const answerLabel = answer.label.label ? answer.label.label : answer.label;

    // And - No action
    if (action === "and" || !action) {
      if (
        (Array.isArray(userAnswer) && userAnswer.length === 0) ||
        !userAnswer
      ) {
        isMatched = false;
        break;
      }
      // And Equal
      if (relation === "equal") {
        if (question.type === "multipleChoice") {
          if (!userAnswer.includes(answerLabel)) {
            isMatched = false;
            break;
          }
        } else if (["number", "decimal"].includes(question.type)) {
          if (!isNumberEqualCondition(userAnswer, condition)) {
            isMatched = false;
            break;
          }
        } else {
          if (userAnswer !== answerLabel) {
            isMatched = false;
            break;
          }
        }
        // And Not Equal
      } else {
        if (question.type === "multipleChoice") {
          if (userAnswer.includes(answerLabel)) {
            isMatched = false;
            break;
          }
        } else if (["number", "decimal"].includes(question.type)) {
          if (isNumberEqualCondition(userAnswer, condition)) {
            isMatched = false;
            break;
          }
        } else {
          if (userAnswer === answerLabel) {
            isMatched = false;
            break;
          }
        }
      }
      // OR
    } else {
      isMatched = false;
      if (
        (Array.isArray(userAnswer) && userAnswer.length === 0) ||
        !userAnswer
      ) {
        continue;
      }
      // Or Equal
      if (relation === "equal") {
        if (question.type === "multipleChoice") {
          if (userAnswer.includes(answerLabel)) {
            isMatched = true;
            break;
          }
        } else if (["number", "decimal"].includes(question.type)) {
          if (isNumberEqualCondition(userAnswer, condition)) {
            isMatched = true;
            break;
          }
        } else {
          if (userAnswer === answerLabel) {
            isMatched = true;
            break;
          }
        }
        // Or Not Equal
      } else {
        if (question.type === "multipleChoice") {
          if (!userAnswer.includes(answerLabel)) {
            isMatched = true;
            break;
          }
        } else if (["number", "decimal"].includes(question.type)) {
          if (!isNumberEqualCondition(userAnswer, condition)) {
            isMatched = true;
            break;
          }
        } else {
          if (userAnswer !== answerLabel) {
            isMatched = true;
            break;
          }
        }
      }
    }
  }

  return isMatched;
};

export const getVisibleQuestions = ({ questions, patientInfo }) => {
  const visibleQuestions = [];
  questions.forEach((question, index) => {
    if (index > 1) {
      if (
        matchedQuestionsBasedOnAgeAndGender({
          patientInfo,
          currentQuestion: question,
        })
      ) {
        visibleQuestions.push({ name: "question" + index, ...question });
      }
    }
  });
  return visibleQuestions;
};
