import React from "react";

import { NavLink } from "react-router-dom";

import useTokenData from "../hooks/useTokenData";
import useTwilioStatus from "../hooks/useTwilioStatus";
import { useSwitchOrdering } from "../store";

function Settings() {
  const { twilioStatus } = useTwilioStatus();
  const { isOrderingMode } = useSwitchOrdering();
  const { individualPracticeOwner, userRoles, entityType } = useTokenData();

  return (
    <>
      <div className="w-full py-4 sm:px-8">
        <h4 className="mb-4 text-center text-4xl font-bold sm:text-left">
          Settings
        </h4>
        <p className="mb-2 text-center text-gray-700 sm:text-left">
          Manage your entity details
        </p>
        <div className="flex w-full justify-between py-4">
          {
            //when rendering dashboard
            !isOrderingMode ? (
              <div className="w-full cursor-pointer first:overflow-hidden first:rounded-t-lg last:overflow-hidden last:rounded-b-lg">
                {userRoles === "Admin" && (
                  <NavLink to={`/rendering/settings/DepositSettings/`}>
                    <div className="flex w-full items-center justify-between bg-white p-5">
                      <div>
                        <div className="mb-2 w-full font-semibold">
                          <span className="mr-2 justify-center rounded-full border border-black px-[8px] pb-[2px]">
                            $
                          </span>
                          Deposit Method
                        </div>
                        <div className="text-gray-600">
                          Select your preferred deposit method to receive your
                          payments
                        </div>
                      </div>
                      <i className="pi pi-angle-right" />
                    </div>
                  </NavLink>
                )}
                <NavLink to={`/rendering/settings/ProfileSettings`}>
                  <div className="flex w-full items-center justify-between bg-white p-5">
                    <div>
                      <div className="mb-2 w-full font-semibold">
                        <span className="pi pi-user-edit mr-1.5" />
                        Edit Profile
                      </div>
                      <div className="text-gray-600">
                        Update your profile information
                      </div>
                    </div>
                    <i className="pi pi-angle-right" />
                  </div>
                </NavLink>
                {/* {!IsAdmin && ( */}
                <NavLink to={`/rendering/settings/security`}>
                  <div className="flex w-full items-center justify-between bg-white p-5">
                    <div>
                      <div className="mb-2 w-full font-semibold">
                        <span className="pi pi-lock mr-1.5" />
                        Security Settings
                      </div>
                      <div className="text-gray-600">
                        Manage your security settings, including your password
                        and PIN
                      </div>
                    </div>
                    <i className="pi pi-angle-right" />
                  </div>
                </NavLink>
                {/* )} */}

                {userRoles === "Admin" && entityType == 1 ? (
                  <NavLink to={`/rendering/settings/groupSettings/`}>
                    <div className="flex w-full items-center justify-between bg-white p-5">
                      <div>
                        <div className="mb-2 w-full font-semibold">
                          <span className="pi pi-users mr-1.5" />
                          Edit Group Practice Information
                        </div>
                        <div className="text-gray-600">
                          Update your group practice information presented
                        </div>
                      </div>
                      <i className="pi pi-angle-right" />
                    </div>
                  </NavLink>
                ) : userRoles === "Admin" && entityType == 2 ? (
                  <NavLink to={`/rendering/settings/individualSetting`}>
                    <div className="flex w-full items-center justify-between bg-white p-5">
                      <div>
                        <div className="mb-2 w-full font-semibold">
                          <span className="pi pi-users mr-1.5" />
                          Edit Individual Practice Information
                        </div>
                        <div className="text-gray-600">
                          Update your individual practice information presented
                        </div>
                      </div>
                      <i className="pi pi-angle-right" />
                    </div>
                  </NavLink>
                ) : null}
                {userRoles === "Admin" && (
                  <NavLink to={`/rendering/settings/OrderSettings/`}>
                    <div className="flex w-full items-center justify-between bg-white p-5">
                      <div>
                        <div className="mb-2 w-full font-semibold">
                          <i className="pi pi-shopping-cart pr-2"></i> Orders
                          Settings
                        </div>
                        <div className="text-gray-600">
                          Edit your orders settings
                        </div>
                      </div>
                      <i className="pi pi-angle-right" />
                    </div>
                  </NavLink>
                )}
                {twilioStatus && (
                  <NavLink to={`/rendering/settings/privacy`}>
                    <div className="flex w-full items-center justify-between bg-white p-5">
                      <div>
                        <div className="mb-2 w-full font-semibold">
                          <span className="pi pi-shield mr-1.5" />
                          Privacy Preferences
                        </div>
                        <div className="text-gray-600">
                          Manage your privacy and communication preferences
                        </div>
                      </div>
                      <i className="pi pi-angle-right" />
                    </div>
                  </NavLink>
                )}
              </div>
            ) : (
              // when ordering dashboard
              <div className="w-full cursor-pointer first:overflow-hidden first:rounded-t-lg last:overflow-hidden last:rounded-b-lg">
                <NavLink to={`/ordering/settings/ProfileSettings`}>
                  <div className="flex w-full items-center justify-between bg-white p-5">
                    <div>
                      <div className="mb-2 w-full font-semibold">
                        <span className="pi pi-user-edit mr-1.5" />
                        Edit My Profile
                      </div>
                      <div className="text-gray-600">
                        Update your personal and verification information
                      </div>
                    </div>
                    <i className="pi pi-angle-right" />
                  </div>
                </NavLink>
                {/* {!IsAdmin && ( */}
                <NavLink to={`/ordering/settings/Security`}>
                  <div className="flex w-full items-center justify-between bg-white p-5">
                    <div>
                      <div className="mb-2 w-full font-semibold">
                        <span className="pi pi-lock mr-1.5" />
                        Security Settings
                      </div>
                      <div className="text-gray-600">
                        Manage your security settings, including your password
                        and PIN
                      </div>
                    </div>
                    <i className="pi pi-angle-right" />
                  </div>
                </NavLink>
                {/* )} */}

                {userRoles === "Admin" && entityType == 2 ? (
                  <NavLink to={`/ordering/settings/individualSetting`}>
                    <div className="flex w-full items-center justify-between bg-white p-5">
                      <div>
                        <div className="mb-2 w-full font-semibold">
                          <span className="pi pi-verified mr-1.5" />
                          Edit Individual Practice Information
                        </div>
                        <div className="text-gray-600">
                          Update your individual practice information
                        </div>
                      </div>
                      <i className="pi pi-angle-right" />
                    </div>
                  </NavLink>
                ) : null}
                {individualPracticeOwner === "False" ? (
                  <NavLink to={`/ordering/settings/verifyProvider`}>
                    <div className="flex w-full items-center justify-between bg-white p-5">
                      <div>
                        <div className="mb-2 w-full font-semibold">
                          <span className="pi pi-verified mr-1.5" />
                          provider verification
                        </div>
                        <div className="capitalize text-gray-600">
                          manage provider verification information
                        </div>
                      </div>
                      <i className="pi pi-angle-right" />
                    </div>
                  </NavLink>
                ) : null}

                {entityType == 1 && userRoles === "Admin" ? (
                  <>
                    <NavLink to={`/ordering/settings/groupSettings/`}>
                      <div className="flex w-full items-center justify-between bg-white p-5">
                        <div>
                          <div className="mb-2 w-full font-semibold">
                            <span className="pi pi-users mr-1.5" />
                            Edit Group Practice Information
                          </div>
                          <div className="text-gray-600">
                            Update your Group Practice information presented
                          </div>
                        </div>
                        <i className="pi pi-angle-right" />
                      </div>
                    </NavLink>
                  </>
                ) : null}
                {twilioStatus && (
                  <NavLink to={`/ordering/settings/privacy`}>
                    <div className="flex w-full items-center justify-between bg-white p-5">
                      <div>
                        <div className="mb-2 w-full font-semibold">
                          <span className="pi pi-shield mr-1.5" />
                          Privacy Preferences
                        </div>
                        <div className="text-gray-600">
                          Manage your privacy and communication preferences
                        </div>
                      </div>
                      <i className="pi pi-angle-right" />
                    </div>
                  </NavLink>
                )}
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}

export default Settings;
