import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useOverlayScrollListener } from "primereact/hooks";
import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import useHandleResize from "../../hooks/useHandleResize";
import ReusableDataList from "../ReusableDataList/ReusableDataList";
import useDelayedInputChange from "../../hooks/useDelayedInputChange";
import EmbeddedStripeDialog from "../embeddedStripe/EmbeddedStripeDialog";
import { InputMask } from "primereact/inputmask";
import {
  downloadFileFromResponse,
  formatDate,
  formatDateTime,
  ORDERING_DROPDOWN_OPTIONS,
} from "../utility/Utils";
import DialogCreatePin from "../settings/DialogCreatePin";
import DialogEnterPin from "../settings/DialogEnterPin";
import useTwilioStatus from "../../hooks/useTwilioStatus";

function OrderingOpen() {
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef(null);
  const menuRight = useRef({});
  const toast = useRef(null);
  const { entityId, userId, HasPin } = useTokenData();
  const [isLoading, setIsLoading] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const { http } = useAxios();
  const [refetch, setRefetch] = useState();
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [dialogContent, setDialogContent] = useState();
  const [isPayLaterDialog, setIsPayLaterDialog] = useState(false);
  const [showDialogCreatePin, setShowDialogCreatePin] = useState(false);
  const [showDialogEnterPin, setShowDialogEnterPin] = useState(false);
  const [isLoadingDialogPin, setIsLoadingDialogPin] = useState(false);
  const [rowDataWithPin, setRowDataWithPin] = useState(null);
  const [rowStatue, setRowStatus] = useState();
  // let rowDataWithPin;
  const [pinCode, setPinCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePinSubmit = (pin) => {
    setPinCode(pin);
    updateOrder(rowDataWithPin, rowStatue, pin);
  };
  const [link, setLink] = useState(null);
  const { twilioStatus } = useTwilioStatus();

  const [selectedValue, setSelectedValue] = useState("Service.Name@=*");
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
    selectedValue,
  );

  const [paymentLinkId, setPaymentLinkId] = useState();
  const { isMobile } = useHandleResize();
  const navigate = useNavigate();

  const defaultValues = {
    name: "",
    phone: "",
    email: "",
    confirmEmail: "",
  };

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const sendLinkOptions = twilioStatus
    ? [
        { label: "Patient Email", value: "email" },
        { label: "Patient SMS", value: "sms" },
        { label: "Patient Email and SMS", value: "Both" },
      ]
    : [{ label: "Patient Email", value: "email" }];

  const handleScroll = () => {
    setVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: visible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  const createPaymentLink = async (data) => {
    try {
      const response = await http.post("/Payments/CreatePaymentLink", {
        successUrl: "https://localhost:44413/PaymentSuccess",
        orderId: data.id,
        productWithPrice: {
          name: data.serviceName,
          amount: data.charge,
        },
      });

      return response.data;
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message || "Something went wrong.",
        life: 3000,
      });
      return null;
    }
  };

  const getPaymentLink = async (orderId) => {
    try {
      const response = await http.get(`/Orders/GetOrderPaymentLink/${orderId}`);
      setExpirationDate(response.data.expiresAt);
      return response.data.paymentLink;
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message || "Something went wrong.",
        life: 3000,
      });
    }
    return null;
  };

  const regenerateLink = async (orderId, shouldRefetch) => {
    setIsRegenerating(true);
    try {
      const response = await http.post("/Payments/RegeneratePaymentLink", {
        successUrl: "https://localhost:44413/ordering/Orders/",
        orderId: orderId,
      });

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Payment link regenerated successfully",
        life: 3000,
      });
      setLink(response.data.paymentLink);
      setExpirationDate(response.data.expiresAt);
      shouldRefetch && setRefetch(!refetch);
      return true;
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message || "Something went wrong.",
        life: 3000,
      });
      return null;
    } finally {
      setIsRegenerating(false);
    }
  };

  const showPaymentDialog = async (rowData, action) => {
    setDialogContent(action);
    setOrderData(rowData);
    if (rowData.paymentLink) {
      const paymentLink = await getPaymentLink(rowData.id);
      if (!paymentLink) return;

      const urlObj = new URL(paymentLink);
      const id = urlObj.searchParams.get("Id");
      setPaymentLinkId(id);
      setLink(paymentLink);
      setIsVisibleDialog(true);
    } else {
      const response = await createPaymentLink(rowData);
      if (!response.paymentLink) return;

      setPaymentLinkId(response.id);
      setLink(response.paymentLink);
      setIsVisibleDialog(true);
      setRefetch(!refetch);
    }
  };
  const updateOrder = (rowData, status, pinCode) => {
    const message =
      status === 4
        ? "By clicking on 'Confirm' button, this order will be cancelled."
        : status === 2
          ? "By clicking on 'Confirm' button, this order will be denied."
          : status === 3
            ? "By clicking on 'Confirm' button, this order will be approved."
            : "Under Development";

    const header =
      status === 4
        ? "Cancel This Order?"
        : status === 2
          ? "Deny This Order?"
          : status === 3
            ? "Approve This Order?"
            : "Under Development";

    const toastMsg =
      status === 4
        ? "Order Cancelled Successfully"
        : status === 2
          ? "Order Denied Successfully"
          : status === 3
            ? "Order Approved Successfully"
            : "Under Development";
    confirmDialog({
      message: message,
      header: header,
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Confirm",
      rejectLabel: "Cancel",
      accept: async () => {
        try {
          if (status) {
            const response = await http
              .put(`/Orders/UpdateOrderAsyncOrderingProvider/${rowData.id}`, {
                status: status,
                cancelationResone: null,
                pinCode: pinCode,
              })
              .then((response) => {
                if (response.status === 200 || response.status === "200")
                  setShowDialogEnterPin(false);
              });
            setRefetch(() => !refetch);
            setPinCode(null);

            pinCode = null;
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: toastMsg,
              life: 3000,
            });
            if (response.status === 200 || response.status === "200") setShowDialogEnterPin(false);
              
            setErrorMessage("");
            console.log(response);
          }
        } catch (error) {
          console.error(error);
          if (!showDialogEnterPin) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.response?.data?.message || "Something went wrong.",
              life: 3000,
            });
          } else {
            setErrorMessage(error.response?.data?.message);
            setPinCode(null);
            pinCode = null;
            // setShowDialogEnterPin(true);
          }
        }
      },
    });
  };

  const resendConfirmMessage = async (rowData) => {
    try {
      const response = await http.post(
        `/Orders/ResendProviderApprovalRequest/${rowData.id}`,
      );
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: response?.data?.message,
        life: 4000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message,
        life: 4000,
      });
    }
  };

  const downloadServiceDocs = async (rowData) => {
    setIsDownloading(true);
    try {
      const response = await http.get(
        `/Orders/DownloadOrderDocuments/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message,
        life: 4000,
      });
    }
    setIsDownloading(false);
  };

  const downloadOrderSummary = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadOrderSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message,
        life: 3000,
      });
    }
  };
  const deleteOrder = (rowData) => {
    confirmDialog({
      message:
        "By click on 'delete' button, this order will be deleted permanently.",
      header: "Delete This Order?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      accept: async () => {
        try {
          await http.delete(`/Orders/${rowData.id}`);
          setRefetch(() => !refetch);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Order Deleted Successfully",
            life: 3000,
          });
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response?.data?.message || "Something went wrong.",
            life: 3000,
          });
        }
      },
    });
  };

  const getMenuItemsStatus = (rowData) => {
    const items = [];
    if (rowData.status === 1 && rowData.assignedProviderId === userId) {
      items.push({
        label: "Approve",
        icon: "pi pi-check",
        command: () => {
          updateOrder(rowData, 3);
        },
        className: "menu-item-hover",
      });

      items.push({
        label: "Deny",
        icon: "pi pi-ban",
        command: () => updateOrder(rowData, 2),
        className: "menu-item-hover",
      });
    }

    return items;
  };

  const statusTemplate = (rowData) => {
    // Customize this function based on your actionTemplate logic
    return (
      <>
        {rowData.status === 1 ? (
          <>
            <span
              ref={buttonRef}
              onClick={(event) => {
                setVisible(true);
                menuRight.current[rowData.id].toggle(event);
              }}
              aria-controls={`popup_menu_right_${rowData.id}`}
              aria-haspopup
              className="inline rounded-md bg-blue-100 p-2 text-center text-sm text-blue-600 sm:block sm:p-3 sm:text-base"
            >
              Pending Approval
            </span>
            <Menu
              model={getMenuItemsStatus(rowData)}
              popup
              ref={(el) => (menuRight.current[rowData.id] = el)}
              id={`popup_menu_right_${rowData.id}`}
              className={visible ? "" : "hidden"}
            />
          </>
        ) : (
          <span className="inline rounded-md bg-yellow-100 p-2 text-center text-sm text-yellow-700 sm:block sm:p-3 sm:text-base">
            Pending Payment
          </span>
        )}
      </>
    );
  };
  const serviceTypeTemplate = (rowData) => {
    return (
      <>
        {rowData.serviceType === 1 ? (
          <p className="inline rounded-md bg-sky-100 p-2 text-center text-sm text-sky-600 sm:block sm:p-3 sm:text-base">
            Scheduled Appointment Service
          </p>
        ) : (
          <p
            className="inline rounded-md p-2 text-center text-sm sm:block sm:p-3 sm:text-base"
            style={{ backgroundColor: "#A0D9D9", color: "#2F4F4F" }}
          >
            Walk-In Service
          </p>
        )}
      </>
    );
  };

  const getMenuItems = (rowData) => {
    const items = [];
    if (rowData.hasDocuments) {
      items.push({
        label: "Download Order Documents",
        icon: isDownloading ? "pi pi-spin pi-spinner" : "pi pi-download",
        command: () => downloadServiceDocs(rowData),
      });
    }

    items.push({
      label: "Download Order Summary",
      icon: "pi pi-download",
      command: () => downloadOrderSummary(rowData),
    });

    if (rowData.status === 1 && rowData.assignedProviderId === userId) {
      items.push({
        label: "Approve",
        icon: "pi pi-check",
        command: () => {
          setPinCode(null);
          if (HasPin === "False") {
            setShowDialogCreatePin(true);
          } else {
            setRowStatus(3);
            setRowDataWithPin(rowData);
            setShowDialogEnterPin(true);
          }
        },
      });

      items.push({
        label: "Deny",
        icon: "pi pi-ban",
        command: () => {
          setPinCode(null);
          if (HasPin === "False") {
            setShowDialogCreatePin(true);
          } else {
            setRowStatus(2);
            setRowDataWithPin(rowData);
            setShowDialogEnterPin(true);
          }
          // updateOrder(rowData, 2)
        },
      });
    } else if (rowData.status === 3) {
      if (!rowData.isExpired) {
        items.push({
          label: "Pay Now",
          icon: "pi pi-dollar",
          command: () => {
            showPaymentDialog(rowData, "now");
          },
        });

        items.push({
          label: "Show Payment Link",
          icon: "pi pi-eye",
          command: () => {
            showPaymentDialog(rowData);
          },
        });
      } else if (rowData.isExpired) {
        items.push({
          label: "Regenerate Payment Link",
          icon: "pi pi-replay",
          command: () => {
            regenerateLink(rowData.id, true);
          },
        });
      }

      items.push({
        label: "Cancel",
        icon: "pi pi-times",
        command: () => updateOrder(rowData, 4),
      });
    } else {
      items.push({
        label: "Waiting for provider action",
        icon: "pi pi-clock",
      });
      items.push({
        label: "Resend The Order Request",
        icon: "pi pi-reload",
        command: () => resendConfirmMessage(rowData),
      });
    }
    items.push({
      label: "Delete",
      icon: "pi pi-trash",
      command: () => deleteOrder(rowData),
    });
    return items;
  };

  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };

  const chargeTemplate = (rowData) => (
    <>
      <h4 className="font-inter text-sm font-semibold"> $ {rowData.charge} </h4>
    </>
  );

  const dropdownValues = {
    options: ORDERING_DROPDOWN_OPTIONS,
    selectedValue: selectedValue,
    setSelectedValue: setSelectedValue,
  };

  const [selectedPayOption, setSelectedPayOption] = useState(null);
  const handleOptionChange = (e) => {
    setSelectedPayOption(e.value);
  };
  let chooseValue = 0;
  if (selectedPayOption === "email") {
    chooseValue = 1;
  } else if (selectedPayOption === "sms") {
    chooseValue = 2;
  } else if (selectedPayOption === "Both") {
    chooseValue = 3;
  }

  const getErrorMessage = (fieldName) => {
    return errors[fieldName]?.message ? (
      <p className="text-red-500">{errors[fieldName]?.message}</p>
    ) : (
      <p className="text-red-500">&nbsp;</p>
    );
  };

  const sendEmail = async (formData) => {
    setIsLoading(true);
    let paymentLink;
    if (!link) {
      paymentLink = await createPaymentLink(orderData);
      if (!paymentLink) {
        setIsLoading(false);
        return;
      }
      setLink(paymentLink);
    }
    try {
      await http.post(`/Payments/SendPaymentLink/${orderData.id}`, {
        emailData: {
          email: formData.email,
          confirmEmail: formData.confirmEmail,
          userName: formData.name,
        },
        messageOptions: chooseValue,
      });
      setPopupMessage("Message sent successfully ... ");
      setShowPopup(true);
      setIsVisibleDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Send success",
        life: 3000,
      });
      setSelectedPayOption("");
      reset();
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message,
        life: 3000,
      });
    }
    setIsLoading(false);
  };

  const sendSMS = async (formData) => {
    setIsLoading(true);
    let paymentLink;
    if (!link) {
      paymentLink = await createPaymentLink(orderData);
      if (!paymentLink) {
        setIsLoading(false);
        return;
      }
      setLink(paymentLink);
    }
    try {
      await http.post(
        `/Payments/SendPaymentLink/${orderData.id}`,

        {
          smsData: {
            toPhoneNumber: formData.phone,
          },
          messageOptions: chooseValue,
        },
      );
      setPopupMessage("Message sent successfully ... ");
      setShowPopup(true);
      setIsVisibleDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Send success",
        life: 3000,
      });
      setSelectedPayOption("");
      reset();
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message,
        life: 3000,
      });
    }
    setIsLoading(false);
  };

  const sendBoth = async (formData) => {
    setIsLoading(true);
    let paymentLink;
    if (!link) {
      paymentLink = await createPaymentLink(orderData);
      if (!paymentLink) {
        setIsLoading(false);
        return;
      }
      setLink(paymentLink);
    }
    try {
      await http.post(
        `/Payments/SendPaymentLink/${orderData.id}`,

        {
          emailData: {
            email: formData.email,
            confirmEmail: formData.confirmEmail,
            userName: formData.name,
          },
          smsData: {
            toPhoneNumber: formData.phone,
          },
          messageOptions: chooseValue,
        },
      );
      setPopupMessage("Message sent successfully ... ");
      setShowPopup(true);
      setIsVisibleDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Send success",
        life: 3000,
      });
      setSelectedPayOption("");
      reset();
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message,
        life: 3000,
      });
    }
    setIsLoading(false);
  };

  const handleCompletePayment = () => {
    setIsVisibleDialog(false);
    setSelectedPayOption("");
    reset();

    navigate(`/return?status=complete&id=${paymentLinkId}`);
  };

  const cardTemplate = (row) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <p>#{row.orderTrackingNumber}</p>
          <p className="text-slate-500">{formatDate(row.orderedDate)}</p>
        </div>
        <div className="flex justify-between gap-2">
          <p className="text-lg font-bold">{row.serviceName}</p>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{row.charge}</span>
          </p>
        </div>
        <p className="font-semibold"> {serviceTypeTemplate(row)}</p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="text-slate-500">&nbsp;Ordered By:&nbsp;</span>
          {row.orderedBy}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="text-slate-500">&nbsp;Patient Name:&nbsp;</span>
          {row.patientName}
        </p>

        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 font-semibold">
            <span className="text-slate-500">Status:&nbsp;</span>
            {statusTemplate(row)}
          </div>
          {actionTemplate(row)}
        </div>
      </>
    );
  };

  const filterTemplate = () => {
    return (
      <div>
        <h3 className="text-md mb-3 font-bold">Search By</h3>
        <div className="flex flex-col gap-2">
          {dropdownValues.options.map((option) => {
            return (
              <div key={option.name} className="align-items-center flex">
                <RadioButton
                  inputId={option.name}
                  name="name"
                  value={option}
                  onChange={(e) =>
                    dropdownValues?.setSelectedValue(e.value.value)
                  }
                  checked={dropdownValues.selectedValue === option.value}
                />
                <label htmlFor={option.name} className="ml-2 capitalize">
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // dialog create pin
  const footerContent = (
    <div>
      <Button
        label="create"
        icon="pi pi-check"
        type="submit"
        form="createPinForm"
        isLoading={isLoadingDialogPin}
        autoFocus
        className="mt-9 w-full justify-center rounded-lg bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
      />
    </div>
  );
  const getRemainingHours = (date) => {
    const currentDate = new Date();
    const futureDate = new Date(date);

    const differenceMs = futureDate - currentDate;

    return differenceMs / (1000 * 60 * 60);
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog className="max-w-[50%]" />
      <Dialog
        blockScroll
        draggable={false}
        header="Complete Payment"
        visible={isVisibleDialog}
        onHide={() => {
          setIsVisibleDialog(false);
          setSelectedPayOption("");
          reset();
        }}
        className="w-[95vw] md:w-[70vw] lg:w-[60vw] xl:w-[50vw]"
      >
        <Tooltip target={".tool-tip"} />
        {dialogContent === "now" && (
          <EmbeddedStripeDialog
            paymentLinkId={paymentLinkId}
            handleComplete={handleCompletePayment}
          />
        )}
        {dialogContent !== "now" && (
          <div>
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-12 rounded-lg border">
                <div className="col-span-10 flex items-center justify-center p-2">
                  <a
                    className="w-full text-blue-500 transition-all duration-150 hover:text-blue-700"
                    target="_blank"
                    rel="noreferrer"
                    href={link}
                  >
                    {link}
                  </a>
                </div>
                <div
                  className="p4 col-span-2 flex cursor-pointer justify-center bg-slate-50 p-4 hover:bg-slate-100"
                  onClick={async () => {
                    if (isCopied) return;
                    // ** This feature is available only in secure contexts (HTTPS)
                    await navigator.clipboard.writeText(link);
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 2500);
                  }}
                >
                  <div className="flex items-center gap-1 px-3">
                    <i
                      className={`pi pi-${isCopied ? "check" : "copy"}`}
                      style={{ fontSize: "1.125rem" }}
                    ></i>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between gap-4 text-sm italic text-gray-500 sm:flex-row">
                {expirationDate &&
                  (expirationDate === "0001-01-02T00:00:00Z" ? (
                    <p className="text-red-500">Please reload the page.</p>
                  ) : (
                    <>
                      <div>
                        <p>
                          This link will expire at{" "}
                          <strong>
                            {`${formatDate(expirationDate)} - ${formatDateTime(expirationDate)}`}
                          </strong>
                        </p>
                        {getRemainingHours(expirationDate) <= 1 && (
                          <p className="text-red-500">
                            <strong>Warning: </strong>Less than one hour left
                            until expiration.
                          </p>
                        )}
                      </div>
                      <Button
                        type="button"
                        severity="info"
                        onClick={async () => await regenerateLink(orderData.id)}
                        icon="pi pi-sync"
                        label="Regenerate payment link"
                        loading={isRegenerating}
                      />
                    </>
                  ))}
              </div>
            </div>
            <div className="my-8 grid grid-cols-3 items-center text-gray-400">
              <hr className="border-gray-400" />
              <p className="text-center text-sm">OR</p>
              <hr className="border-gray-400" />
            </div>
            <div>
              <div className="mb-4">
                <h2 className="font-bold">
                  Send payment link to the patient via:
                </h2>
              </div>
              <div>
                <Dropdown
                  value={selectedPayOption}
                  options={sendLinkOptions}
                  onChange={handleOptionChange}
                  placeholder="Select an option"
                  optionLabel="label"
                  style={{ width: "100%", marginBottom: "1rem" }}
                />
                {selectedPayOption === "email" && (
                  <form
                    onSubmit={handleSubmit(sendEmail)}
                    className="flex flex-col gap-1"
                  >
                    <div>
                      <label>
                        Patient Name <span className="text-red-600">*</span>
                      </label>
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-user" />
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              {...field}
                              placeholder="Patient Name"
                              className="w-full pb-2 pt-2"
                            />
                          )}
                          rules={{ required: "Patient name is required" }}
                        />
                      </span>
                      {getErrorMessage("name")}
                    </div>
                    <div>
                      <label>
                        Patient Email <span className="text-red-600">*</span>
                      </label>
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-envelope" />
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              {...field}
                              placeholder="Patient Email"
                              className="w-full pb-2 pt-2"
                            />
                          )}
                          rules={{
                            required: "Patient email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid Email Address",
                              matchesEmail: (value) =>
                                value === getValues("confirmEmail") ||
                                "Emails do not match",
                            },
                          }}
                        />
                      </span>
                      {getErrorMessage("email")}
                    </div>
                    <div>
                      <label>
                        Confirm Patient Email{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-lock" />
                        <Controller
                          name="confirmEmail"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              {...field}
                              placeholder="Confirm Patient Email"
                              className="w-full pb-2 pt-2"
                            />
                          )}
                          rules={{
                            required: "Please confirm patient email",
                            validate: {
                              matchesEmail: (value) =>
                                value === getValues("email") ||
                                "Emails do not match",
                            },
                          }}
                        />
                      </span>
                      {getErrorMessage("confirmEmail")}
                    </div>

                    <div className="flex justify-end">
                      <Button
                        disabled={isLoading}
                        icon={isLoading ? "pi pi-spin pi-spinner" : undefined}
                        rounded
                        className="w-full"
                        label={isLoading ? "Sending..." : "Send Email"}
                      />
                    </div>
                  </form>
                )}
                {selectedPayOption === "sms" && (
                  <form
                    onSubmit={handleSubmit(sendSMS)}
                    className="flex flex-col gap-1"
                  >
                    <div>
                      <label>
                        Patient phone Number
                        <span className="text-red-600">*</span>
                      </label>
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-phone" />
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <InputMask
                              id={field.name}
                              value={field.value}
                              autoClear={false}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                              onClick={(e) => {
                                if (e.target.value === "___-___-____") {
                                  e.target.setSelectionRange(0, 0);
                                }
                              }}
                              mask="(999) 999-9999"
                              placeholder="(999) 999-9999"
                              className="w-full"
                            />
                          )}
                          rules={{
                            required: "Patient phone number is required",
                            pattern: {
                              value: "^(d{3}) d{3}-d{4}$",
                              message: "Invalid phone number",
                            },
                            validate: {
                              complete: (value) => {
                                return (
                                  value.indexOf("_") === -1 ||
                                  "Incomplete phone number"
                                );
                              },
                            },
                          }}
                        />
                      </span>
                      {errors.phone && (
                        <span className="text-red-600">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>
                    <div className="flex justify-end">
                      <Button
                        disabled={isLoading}
                        icon={isLoading ? "pi pi-spin pi-spinner" : undefined}
                        rounded
                        className="w-full"
                        label={isLoading ? "Sending..." : "Send SMS Message"}
                      />
                    </div>
                  </form>
                )}
                {selectedPayOption === "Both" && (
                  <form
                    onSubmit={handleSubmit(sendBoth)}
                    className="flex flex-col gap-1"
                  >
                    <div>
                      <label>
                        Patient Name <span className="text-red-600">*</span>
                      </label>
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-user" />
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              {...field}
                              placeholder="Patient Name"
                              className="w-full pb-2 pt-2"
                            />
                          )}
                          rules={{ required: "Patient name is required" }}
                        />
                      </span>
                      {getErrorMessage("name")}
                    </div>
                    <div>
                      <label>
                        Patient Email <span className="text-red-600">*</span>
                      </label>
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-envelope" />
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              {...field}
                              placeholder="Patient Email"
                              className="w-full pb-2 pt-2"
                            />
                          )}
                          rules={{
                            required: "Patient email address is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid Email Address",
                              matchesEmail: (value) =>
                                value === getValues("confirmEmail") ||
                                "Emails do not match",
                            },
                          }}
                        />
                      </span>
                      {getErrorMessage("email")}
                    </div>
                    <div>
                      <label>
                        Confirm Patient Email{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-lock" />
                        <Controller
                          name="confirmEmail"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              {...field}
                              placeholder="Confirm Patient Email"
                              className="w-full pb-2 pt-2"
                            />
                          )}
                          rules={{
                            required: "Please confirm patient email",
                            validate: {
                              matchesEmail: (value) =>
                                value === getValues("email") ||
                                "Emails do not match",
                            },
                          }}
                        />
                      </span>
                      {getErrorMessage("confirmEmail")}
                    </div>
                    <div>
                      <label>
                        Patient Phone Number{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-phone" />
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <InputMask
                              id={field.name}
                              value={field.value}
                              autoClear={false}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                              onClick={(e) => {
                                if (e.target.value === "___-___-____") {
                                  e.target.setSelectionRange(0, 0);
                                }
                              }}
                              mask="(999) 999-9999"
                              placeholder="(999) 999-9999"
                              className="w-full"
                            />
                          )}
                          rules={{
                            required: "Patient phone number is required",
                            pattern: {
                              value: "^(d{3}) d{3}-d{4}$",
                              message: "Invalid phone number",
                            },
                            validate: {
                              complete: (value) => {
                                return (
                                  value.indexOf("_") === -1 ||
                                  "Incomplete phone number"
                                );
                              },
                            },
                          }}
                        />
                      </span>
                      {errors.phone && (
                        <span className="text-red-600">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>
                    <div className="flex justify-end">
                      <Button
                        disabled={isLoading}
                        icon={isLoading ? "pi pi-spin pi-spinner" : undefined}
                        rounded
                        className="w-full"
                        label={isLoading ? "Sending..." : "Send SMS and Email"}
                      />
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        visible={showDialogCreatePin}
        modal
        draggable={false}
        footer={footerContent}
        style={{ width: "40rem" }}
        headerStyle={{ paddingBottom: "0px" }}
        onHide={() => {
          if (!showDialogCreatePin) return;
          setShowDialogCreatePin(false);
        }}
      >
        <DialogCreatePin
          isLoading={isLoadingDialogPin}
          setIsLoading={setIsLoadingDialogPin}
          showDialogCreatePin={showDialogCreatePin}
          setShowDialogCreatePin={setShowDialogCreatePin}
        />
      </Dialog>
      <DialogEnterPin
        showDialogEnterPin={showDialogEnterPin}
        setShowDialogEnterPin={setShowDialogEnterPin}
        errorMessage={errorMessage}
        onPinSubmit={handlePinSubmit}
      />
      <ReusableDataList
        title={"List of orders"}
        sendSearchData={handleInputChange}
        searchValue={searchValue}
        dropdownValues={dropdownValues}
        dataLink={`/Orders/GetOrderingPracticeOrders/${entityId}?Filters=${
          searchValue ? selectedValue : ""
        }${searchValue}%2Cstatus==1|3`}
        columns={[
          {
            name: "Order Number",
            value: "orderTrackingNumber",
            style: { width: "13%" },
          },
          {
            name: "Service Name",
            value: "serviceName",
            style: { width: "15%" },
          },
          {
            name: "Service Type",
            value: "serviceType",
            template: serviceTypeTemplate,
            style: { width: "13%" },
          },
          {
            name: "Charge",
            value: "charge",
            template: chargeTemplate,
            style: { width: "10%" },
          },
          { name: "Ordered By", value: "orderedBy", style: { width: "14%" } },
          {
            name: "Assigned To",
            value: "assignedProviderName",
            style: { width: "14%" },
          },
          { name: "Offered By", value: "offeredBy", style: { width: "14%" } },
          { name: "Date", value: "orderedDate", style: { width: "14%" } },
          {
            name: "Patient Name",
            value: "patientName",
            style: { width: "14%" },
          },
        ]}
        actionTemplates={
          isMobile
            ? [{ template: cardTemplate }]
            : [
                {
                  template: statusTemplate,
                  header: "Status",
                  style: { width: "20%" },
                },
                { template: actionTemplate, header: "Actions" },
              ]
        }
        filterTemplate={filterTemplate}
        refetch={refetch}
      />
      <Dialog
        visible={showPopup}
        draggable={false}
        onHide={() => setShowPopup(false)}
        header="Message"
        modal
        footer={<button onClick={() => setShowPopup(false)}>OK</button>}
      >
        <span className="px-5">{popupMessage}</span>
      </Dialog>
    </>
  );
}

export default OrderingOpen;
