import React from "react";
import medxLogo from "./../../images/Medx@2x.png";

const PrivacyPolicy = () => {
  return (
    <div className="m-auto my-10 min-h-screen w-9/12 rounded-xl bg-white px-7 py-8 leading-relaxed">
      <div className="m-auto rounded-md border border-solid border-light-purple bg-white px-6 text-sm leading-relaxed">
        <img src={medxLogo} alt="medx-logo" className="m-auto w-44" />

        <h1 className="mb-6 bg-gradient-to-r from-[#2c3e50] to-[#3498db] bg-clip-text text-center text-3xl font-bold text-transparent">
          MedX Services Privacy Policy
        </h1>
        <p className="mb-8 text-center text-sm text-gray-600">
          Last Modified: July 07, 2024
        </p>
        <h2 className="mb-4 text-2xl font-semibold">Introduction</h2>
        <p className="mb-4">
          <strong>Accelerated Healthcare Solutions, LLC</strong> ("
          <strong>AHS</strong>"). and its affiliates ("
          <strong>MedX</strong>," "<strong>MedXSVC</strong>," "
          <strong>we</strong>
          ," "<strong>us</strong>," or "<strong>our</strong>") respect your
          privacy and are committed to protecting it through our compliance with
          this Policy.
        </p>
        <p className="mb-4">
          This Policy describes the types of information we may collect from you
          or that you may provide when you visit the website MedXSvc.com (our "
          <strong>Website</strong>") or download and use a MedXSvc mobile
          application (our "<strong>App</strong>", and together with the
          Website, the "<strong>Marketplace</strong>") and our practices for
          collecting, using, maintaining, protecting, and disclosing that
          information.
        </p>

        <p className="mb-4">This Policy applies to information we collect:</p>
        <ul className="mb-4 list-disc pl-8">
          <li>On the Marketplace.</li>
          <li>
            In email, text, and other electronic messages between you and the
            Marketplace.
          </li>
          <li>
            Through mobile and desktop applications used to access the
            Marketplace, which provide dedicated non-browser-based interaction
            between you and the Marketplace.
          </li>
          <li>
            When you interact with our advertising and applications on
            third-party websites and services, if those applications or
            advertising include links to this Policy.
          </li>
        </ul>

        <p className="mb-4">It does not apply to information collected by:</p>
        <ul className="mb-4 list-disc pl-8">
          <li>Us offline or through any other means; or</li>
          <li>
            Any third party, including through any application or content
            (including advertising) that may link to or be accessible from or
            through the Marketplace.
          </li>
        </ul>

        <p className="mb-4">
          Please read this Policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Marketplace. By accessing or using this Marketplace, you agree
          to this Privacy Policy. This Policy may change from time to time. Your
          continued use of this Marketplace after we make changes is deemed to
          be acceptance of those changes, so please check the Policy
          periodically for updates. Capitalized terms not defined in this
          Privacy Policy shall have the meaning assigned to them in the{" "}
          <a
            href="https://medxsvc.com/legal/tos"
            className="text-blue-600 hover:underline"
          >
            MedX Services Marketplace Terms of Service
          </a>{" "}
          ("<strong>Marketplace Terms</strong>" or "<strong>TOU</strong>
          "): https://medxsvc.com/legal/tos.
        </p>

        <h2 className="mb-4 text-2xl font-semibold">
          Children Under the Age of 18
        </h2>
        <p className="mb-4">
          Our Marketplace is not intended for children under 18 years of age. No
          one under age 18 may provide any information to or on the Marketplace.
          We do not knowingly collect personal information from children under
          18. If you are under 18, do not use or provide any information on this
          Marketplace. If we learn we have collected or received personal
          information from a child under 18, we will delete that information. If
          you believe we might have any information from or about a child under
          18, please contact us at{" "}
          <a
            href="mailto:mxlegal@medxsvc.com"
            className="text-blue-600 hover:underline"
          >
            mxlegal@medxsvc.com
          </a>
          .
        </p>
        <h2 className="mb-4 text-2xl font-semibold">
          Information We Collect About You and How We Collect It
        </h2>
        <p className="mb-4">
          We collect several types of information from and about users of our
          Marketplace, including information:
        </p>
        <ul className="mb-4 list-disc pl-8">
          <li>
            By which you may be personally identified, such as name, postal
            address, email address, telephone number, social security number, or
            any other identifier by which you may be contacted online or offline
            ("
            <strong>personal information</strong>");
          </li>
          <li>
            That is about you but individually does not identify you, such as
            ways in which you interact with the Marketplace as a whole or
            Website or App individually, how long you use the Marketplace, etc.;
            and/or
          </li>
          <li>
            About your internet connection, the equipment you use to access our
            Marketplace, and usage details.
          </li>
        </ul>

        <p className="mb-4">We collect this information:</p>
        <ul className="mb-4 list-disc pl-8">
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you navigate through the Website or use the App.
            Information collected automatically may include usage details, IP
            addresses, and information collected through cookies, web beacons,
            and other tracking technologies.
          </li>
          <li>From third parties, including our business partners.</li>
        </ul>

        <h3 className="mb-3 text-xl font-semibold">
          Information You Provide to Us
        </h3>
        <p className="mb-4">
          The information we collect on or through our Marketplace may include:
        </p>
        <ul className="mb-4 list-disc pl-8">
          <li>
            Information that you provide by filling in forms on our Marketplace.
            This includes information provided at the time of registering to use
            our Marketplace, subscribing to our service, posting material, or
            requesting further services. We may also ask you for information
            when you report a problem with our Marketplace.
          </li>
          <li>
            Records and copies of your correspondence (including email
            addresses), if you contact us.
          </li>
          <li>
            Your responses to surveys that we might ask you to complete for
            research purposes.
          </li>
          <li>
            Details of transactions you carry out through our Marketplace and of
            the fulfillment of your orders. You will be required to provide
            financial information in order to receive payment through our
            Marketplace.
          </li>
          <li>Your search queries on the Marketplace.</li>
          <li>
            Your professional and educational background information and
            details, including details about state-specific licensing.
          </li>
        </ul>

        <p className="mb-4">
          You also may provide information to be published or displayed
          (hereinafter, "<strong>posted</strong>") on public areas of the
          Marketplace or transmitted to other users of the Marketplace or third
          parties (collectively, "<strong>User Contributions</strong>"). Your
          User Contributions are posted on and transmitted to others at your own
          risk. Although you may set certain privacy settings for such
          information by logging into your account profile, please be aware that
          no security measures are perfect or impenetrable. Additionally, we
          cannot control the actions of other users of the Marketplace with whom
          you may choose to share your User Contributions. Therefore, we cannot
          and do not guarantee that your User Contributions will not be viewed
          by unauthorized persons.
        </p>

        <h3 className="mb-3 text-xl font-semibold">
          Information We Collect Through Automatic Data Collection Technologies
        </h3>
        <p className="mb-4">
          As you navigate through and interact with our Marketplace, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including:
        </p>
        <ul className="mb-4 list-disc pl-8">
          <li>
            Details of your visits to our Marketplace, including traffic data,
            location data, logs, and other communication data and the resources
            that you access and use on the Marketplace.
          </li>
          <li>
            Information about your computer and internet connection, including
            your IP address, operating system, and browser type.
          </li>
        </ul>

        <p className="mb-4">
          The information we collect automatically may include personal
          information, or we may maintain it or associate it with personal
          information we collect in other ways or receive from third parties. It
          helps us to improve our Marketplace and to deliver a better and more
          personalized service, including by enabling us to:
        </p>
        <ul className="mb-4 list-disc pl-8">
          <li>Estimate our audience size and usage patterns.</li>
          <li>
            Store information about your preferences, allowing us to customize
            our Marketplace according to your individual interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our Marketplace.</li>
        </ul>

        <p className="mb-4">
          The technologies we use for this automatic data collection may
          include:
        </p>
        <ul className="mb-4 list-disc pl-8">
          <li>
            <strong>Cookies (or browser cookies).</strong> A cookie is a small
            file placed on your computer or device. You may refuse to accept
            browser cookies by activating the appropriate setting on your
            browser. However, if you select this setting, you may be unable to
            access certain parts of our Marketplace. Unless you have adjusted
            your browser setting so that it will refuse cookies, our system will
            issue cookies when you direct your browser to our Marketplace. For
            information about managing your privacy and security settings for
            cookies, see{" "}
            <a
              href="/legal/privacy#Choices"
              className="text-blue-600 hover:underline"
            >
              Choices About How We Use and Disclose Your Information
            </a>
            .
          </li>
          <li>
            <strong>Web Beacons.</strong> Pages of our Marketplace and our
            emails may contain small electronic files known as web beacons (also
            referred to as clear gifs, pixel tags, and single-pixel gifs) that
            permit AHS, for example, to count users who have visited those pages
            or opened an email and for other related Marketplace statistics (for
            example, recording the popularity of certain Marketplace content and
            verifying system and server integrity).
          </li>
        </ul>
        <h2 className="mb-4 text-2xl font-semibold">
          Third-Party Use of Cookies and Other Tracking Technologies
        </h2>
        <p className="mb-4">
          Some content or applications, including advertisements, on the
          Marketplace are served by third parties, including advertisers, ad
          networks and servers, content providers, and application providers.
          These third parties may use cookies alone or in conjunction with web
          beacons or other tracking technologies to collect information about
          you when you use our Marketplace. The information they collect may be
          associated with your personal information or they may collect
          information, including personal information, about your online
          activities over time and across different websites and other online
          services. They may use this information to provide you with
          interest-based (behavioral) advertising or other targeted content.
        </p>
        <p className="mb-4">
          We do not control these third parties' tracking technologies or how
          they may be used. If you have any questions about an advertisement or
          other targeted content, you should contact the responsible provider
          directly. For information about how you can opt out of receiving
          targeted advertising from many providers, see{" "}
          <a
            href="/legal/privacy#Choices"
            className="text-blue-600 hover:underline"
          >
            Choices About How We Use and Disclose Your Information
          </a>
          .
        </p>

        <h2 className="mb-4 text-2xl font-semibold">
          How We Use Your Information
        </h2>
        <p className="mb-4">
          We use information that we collect about you or that you provide to
          us, including any personal information:
        </p>
        <ul className="mb-4 list-disc pl-8">
          <li>To present our Marketplace and its contents to you.</li>
          <li>
            To provide our Marketplace functions (including, but not limited to,
            credentialing verification, service posting, and compliance
            purposes).
          </li>
          <li>
            To provide you with information, products, or services that you
            request from us.
          </li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>
            To provide you with notices about your account, including important
            notices.
          </li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us or you and others,
            including for billing and collection.
          </li>
          <li>
            To notify you about changes to our Marketplace or any products or
            services we offer or are made available through it.
          </li>
          <li>
            To allow you to participate in interactive features on our
            Marketplace.
          </li>
          <li>
            In any other way we may describe when you provide the information.
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>

        <p className="mb-4">
          We may also use your information to contact you about goods and
          services that may be of interest to you. If you do not want us to use
          your information in this way, please adjust your user preferences in
          your account profile. For more information, see{" "}
          <a
            href="/legal/privacy#Choices"
            className="text-blue-600 hover:underline"
          >
            Choices About How We Use and Disclose Your Information
          </a>
          .
        </p>

        <p className="mb-4">
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers' target audiences. Even
          though we do not disclose your personal information for these purposes
          without your consent, if you click on or otherwise interact with an
          advertisement, the advertiser may assume that you meet its target
          criteria.
        </p>

        <h2 className="mb-4 text-2xl font-semibold">
          Disclosure of Your Information
        </h2>
        <p className="mb-4">
          We may disclose aggregated information about our users, and
          information that does not identify any individual, without
          restriction.
        </p>

        <p className="mb-4">
          We may disclose personal information that we collect or you provide as
          described in this Privacy Policy:
        </p>
        <ul className="mb-4 list-disc pl-8">
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support our business and who are bound by contractual obligations to
            keep personal information confidential and use it only for the
            purposes for which we disclose it to them.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of AHS's assets, whether as a going concern
            or as part of bankruptcy, liquidation, or similar proceeding, in
            which personal information held by AHS about our Marketplace users
            is among the assets transferred.
          </li>
          <li>
            To third parties <i>participating</i> in the <strong>MedX</strong>{" "}
            Marketplace to market their products or services to you or to market
            your services or products to them (to facilitate sellers and
            purchasers to appropriately connect and be aware of each other by
            using the limited amount of information that you setup as publicly
            visible in your <strong>MedX</strong> Provider Profile). Disclosure
            to third-parties is{" "}
            <strong>
              <u>strictly limited</u>
            </strong>{" "}
            to practice information disclosed by each participating practice and
            any user account specific information is NOT included under this
            provision (personal information is never disclosed).{" "}
            <i>Specifically</i>, your name, your date of birth, your email, your
            phone number, your SMS / text number, or <i>any</i> information
            gathered during the SMS opt-in and consent{" "}
            <strong>
              <u>WILL NOT</u>
            </strong>{" "}
            be disclosed to <strong>ANY</strong> third parties. Furthermore, for
            the limited information that you choose to disclose about your
            practice, third parties does not include any third parties that are
            not actively participating in the MedX Marketplace. None of your
            information will ever be shared or sold with any entity not actively
            doing business with <strong>AHS</strong> or <strong>MedX</strong>,
            for any purpose.
          </li>
          <li>
            To Healthcare Facilities and Healthcare Providers to provide the
            Marketplace functions and to comply with Healthcare Provider
            contracts, applicable laws, and renewals.
          </li>
          <li>
            To fulfill the purpose for which you provide it. For example, if you
            give us an email address to use the "email a peer" feature of our
            Marketplace, we will transmit the contents of that email and your
            email address to the recipients.
          </li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li>With your consent.</li>
        </ul>

        <p className="mb-4">We may also disclose your personal information:</p>
        <ul className="mb-4 list-disc pl-8">
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            To enforce or apply our{" "}
            <a
              href="https://medxsvc.com/legal/tos"
              className="text-blue-600 hover:underline"
            >
              Marketplace Terms of Service
            </a>{" "}
            and other agreements, including for billing and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of AHS, our customers, or others. This
            includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk
            reduction.
          </li>
        </ul>
        {/* Continuing from the previous component */}

        <h2 id="Choices" className="mb-4 text-2xl font-semibold">
          Choices About How We Use and Disclose Your Information
        </h2>
        <p className="mb-4">
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
        </p>

        <ul className="mb-4 list-disc pl-8">
          <li>
            <strong>Tracking Technologies and Advertising.</strong> You can set
            your browser to refuse all or some browser cookies, or to alert you
            when cookies are being sent. If you disable or refuse cookies,
            please note that some parts of this site may then be inaccessible or
            not function properly.
          </li>
          <li>
            <strong>
              Disclosure of Your Information for Third-Party Advertising.
            </strong>{" "}
            If you do not want us to share your personal information with
            unaffiliated or non-agent third parties for promotional purposes,
            you can opt-out by logging into the Marketplace and adjusting your
            user preferences in your account profile if available, checking or
            unchecking the relevant boxes, or by sending us an email with your
            request to{" "}
            <a
              href="mailto:mxlegal@medxsvc.com"
              className="text-blue-600 hover:underline"
            >
              mxlegal@medxsvc.com
            </a>
            .
          </li>
          <li>
            <strong>Promotional Offers from AHS.</strong> If you do not wish to
            have your contact information used by AHS to promote our own or
            third parties' products or services, you can opt-out by logging into
            the Marketplace and adjusting your user preferences in your account
            profile if available, by checking or unchecking the relevant boxes,
            or by sending us an email stating your request to{" "}
            <a
              href="mailto:mxlegal@medxsvc.com"
              className="text-blue-600 hover:underline"
            >
              mxlegal@medxsvc.com
            </a>
            . If we have sent you a promotional email, you may send us a return
            email asking to be omitted from future email distributions. This
            opt-out does not apply to information provided to AHS as a result of
            transactions utilizing the Marketplace.
          </li>
          <li>
            <strong>Targeted Advertising.</strong> If you do not want us to use
            information that we collect or that you provide to us to deliver
            advertisements according to our advertisers' target-audience
            preferences, you can opt-out by emailing us at{" "}
            <a
              href="mailto:mxlegal@medxsvc.com"
              className="text-blue-600 hover:underline"
            >
              mxlegal@medxsvc.com
            </a>
            . For this opt-out to function, you must have your browser set to
            accept all browser cookies.
          </li>
        </ul>

        <p className="mb-4">
          We do not control third parties' collection or use of your information
          to serve interest-based advertising. However, these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative ("NAI") on the NAI's
          Marketplace.
        </p>

        <p className="mb-4">
          Residents of certain states may have additional personal information
          rights and choices. Please see{" "}
          <a
            href="/legal/privacy#State"
            className="text-blue-600 hover:underline"
          >
            Your State Privacy Rights
          </a>{" "}
          for more information.
        </p>

        <h2 className="mb-4 text-2xl font-semibold">
          Accessing and Correcting Your Information
        </h2>
        <p className="mb-4">
          You may send us an email at{" "}
          <a
            href="mailto:mxlegal@medxsvc.com"
            className="text-blue-600 hover:underline"
          >
            mxlegal@medxsvc.com
          </a>{" "}
          to request access to, correct, update, or delete any personal
          information that you have provided to us. We cannot delete your
          personal information except by also deleting your user account. We may
          not accommodate a request to change information if we believe the
          change would violate any law or legal requirement or cause the
          information to be incorrect.
        </p>

        <p className="mb-4">
          If you delete your User Contributions from the Marketplace, copies of
          your User Contributions may remain viewable in cached and archived
          pages or might have been copied or stored by other Marketplace users.
          Proper access and use of information provided on the Marketplace,
          including User Contributions, is governed by our{" "}
          <a
            href="https://medxsvc.com/legal/tos"
            className="text-blue-600 hover:underline"
          >
            Marketplace Terms
          </a>
          .
        </p>

        <p className="mb-4">
          Residents of certain states may have additional personal information
          rights and choices. Please see{" "}
          <a
            href="/legal/privacy#State"
            className="text-blue-600 hover:underline"
          >
            Your State Privacy Rights
          </a>{" "}
          for more information.
        </p>

        <h2 id="State" className="mb-4 text-2xl font-semibold">
          Your State Privacy Rights
        </h2>
        <p className="mb-4">
          State consumer privacy laws may provide their residents with
          additional rights regarding our use of their personal information.
        </p>

        <p className="mb-4">
          California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa,
          Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah,
          and Virginia provide (now or in the future) their state residents with
          rights to:
        </p>

        <ul className="mb-4 list-disc pl-8">
          <li>Confirm whether we process their personal information.</li>
          <li>Access and delete certain personal information.</li>
          <li>
            Correct inaccuracies in their personal information, taking into
            account the information's nature processing purpose (excluding Iowa
            and Utah).
          </li>
          <li>Data portability.</li>
          <li>Opt-out of personal data processing for:</li>
          <ul className="mb-2 list-disc pl-8">
            <li>targeted advertising (excluding Iowa);</li>
            <li>sales; or</li>
            <li>
              profiling in furtherance of decisions that produce legal or
              similarly significant effects (excluding Iowa and Utah).
            </li>
          </ul>
          <li>
            Either limit (opt-out of) or require consent to process sensitive
            personal data.
          </li>
        </ul>

        <p className="mb-4">
          The exact scope of these rights may vary by state. To exercise any of
          these rights please contact us via email using{" "}
          <a
            href="mailto:mxlegal@medxsvc.com"
            className="text-blue-600 hover:underline"
          >
            mxlegal@medxsvc.com
          </a>
          . To appeal a decision regarding a consumer rights request, please
          respond to the email containing the decision you wish to appeal and
          include "APPEAL" as the first word in the subject line and begin the
          body of your email with the statement, "I would like to appeal the
          following decision regarding my privacy rights."
        </p>

        <p className="mb-4">
          Nevada provides its residents with a limited right to opt-out of
          certain personal information sales. Residents who wish to exercise
          this sale opt-out rights may submit a request to this designated
          address:{" "}
          <a
            href="mailto:mxlegal@medxsvc.com"
            className="text-blue-600 hover:underline"
          >
            mxlegal@medxsvc.com
          </a>
          . However, please know we do not currently sell data triggering that
          statute's opt-out requirements.
        </p>
        <p className="mb-4">
          {" "}
          In addition, we provide the following detailed chart regarding our
          collection, use, disclosure for business purposes of personal
          information under California law. We do not sell or share for purposes
          of cross-context behavioral advertising personal information to third
          parties. Note that in addition to the specified Business Purpose
          Disclosures in the table, we may also disclose personal information to
          audit compliance with the CCPA and other standards, help ensure the
          security and integrity of the Marketplace, debug the Website and App,
          to engage in research and development related to the Marketplace, and
          verify or maintain the quality or safety of the Website and App. As
          used below, "Standard Business Services" means maintaining or
          servicing accounts, providing customer service, providing Marketplace
          functions (including, but not limited to, credentialing and background
          verification, shift posting, and compliance functions), verifying
          customer information, processing payments, providing analytic
          services, providing storage, maintaining compliance with applicable
          laws, customer contracts, and third party compliance or accreditation
          organizations, or providing other similar services.{" "}
        </p>
        <div className="overflow-x-auto">
          <table className="mb-8 min-w-full border border-gray-300 bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="border-b px-4 py-2">
                  Personal Information Category
                </th>
                <th className="border-b px-4 py-2">
                  Examples of Information Category
                </th>
                <th className="border-b px-4 py-2">Is Category Collected?</th>
                <th className="border-b px-4 py-2">
                  Business Purpose Disclosures
                </th>
                <th className="border-b px-4 py-2">
                  Categories of Third-Party Recipients
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b px-4 py-2">
                  F: Internet or other similar network activity.
                </td>
                <td className="border-b px-4 py-2">
                  Browsing history, search history, information on a consumer's
                  interaction with a website, application, or advertisement
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  Standard Business Services; product and services
                  recommendations
                </td>
                <td className="border-b px-4 py-2">
                  Advertising Networks; Data Analytics Providers; Operating
                  Systems & Platforms
                </td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">A: Identifiers.</td>
                <td className="border-b px-4 py-2">
                  A real name, alias, postal address, unique personal
                  identifier, online identifier, IP address, email address,
                  account name, Social Security number, driver's license number,
                  passport number, or other similar identifiers.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  Standard Business Services; product and services
                  recommendations
                </td>
                <td className="border-b px-4 py-2">
                  Healthcare Facilities; W2 Workforce Providers; Healthcare
                  Accreditation organizations; Data Analytics Providers;
                  advertising networks; Operating Systems & Platforms; Social
                  Networks; Billing and Payment Processors
                </td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">
                  B: California Customer Records personal information
                  categories.
                </td>
                <td className="border-b px-4 py-2">
                  A name, signature, Social Security number, physical
                  characteristics or description, address, telephone number,
                  driver's license or state identification card number,
                  insurance policy number, education, employment, employment
                  history, bank account number, credit card number. Some
                  personal information included in this category may overlap
                  with other categories.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  Standard Business Services; Product and services
                  recommendations
                </td>
                <td className="border-b px-4 py-2">
                  Healthcare Facilities; W2 Workforce Providers; Healthcare
                  Accreditation organizations; Data Analytics Providers;
                  advertising networks; Operating Systems & Platforms; Social
                  Networks; Billing and Payment Processors
                </td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">
                  C: Protected classification characteristics under California
                  or federal law.
                </td>
                <td className="border-b px-4 py-2">
                  This category includes age (for those 40 years or older),
                  race, color, ancestry, national origin, citizenship, religion
                  or creed, marital status, medical condition, physical or
                  mental disability, and sex.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  Standard Business Services; Meet specific customer
                  requirements for DEI
                </td>
                <td className="border-b px-4 py-2">
                  Healthcare Facilities; W2 Workforce Providers; Healthcare
                  Accreditation organizations.
                </td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">
                  D: Commercial information.
                </td>
                <td className="border-b px-4 py-2">
                  Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  Standard Business Services Account processing, Product and
                  services recommendations
                </td>
                <td className="border-b px-4 py-2">
                  Data Analytics Providers; advertising networks
                </td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">E: Biometric Information</td>
                <td className="border-b px-4 py-2">
                  Behavioral characteristics, including imagery of the face and
                  voice recordings.
                </td>
                <td className="border-b px-4 py-2">No</td>
                <td className="border-b px-4 py-2">N/A</td>
                <td className="border-b px-4 py-2">N/A</td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">G: Geolocation data.</td>
                <td className="border-b px-4 py-2">
                  IP address-based location information and GPS data.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  Standard Business Services; Product and services
                  recommendations
                </td>
                <td className="border-b px-4 py-2">
                  Service providers; advertising partners; analytics partners
                  and other parties you may authorize
                </td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">
                  I: Professional or employment-related information
                </td>
                <td className="border-b px-4 py-2">
                  Current or past job history or performance evaluations.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  application processing; Standard Business Services and
                  background checks
                </td>
                <td className="border-b px-4 py-2">
                  Healthcare Facilities; W2 Workforce Providers; Healthcare
                  Accreditation organizations; Service providers and job
                  application processors
                </td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">
                  H: Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </td>
                <td className="border-b px-4 py-2">
                  COVID-19 related temperature checks; call monitoring and video
                  surveillance.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  Customer service and support; compliance with client
                  requirements
                </td>
                <td className="border-b px-4 py-2">N/A</td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">
                  J: Non-public education information
                </td>
                <td className="border-b px-4 py-2">
                  Education records directly related to a student maintained by
                  an educational institution or party acting on its behalf, such
                  as grades, transcripts, class lists, student schedules,
                  student identification codes, student financial Information,
                  or student disciplinary records.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  application processing and background checks
                </td>
                <td className="border-b px-4 py-2">
                  Healthcare Facilities; W2 Workforce Providers; Healthcare
                  Accreditation organizations; Service providers and job
                  application processors
                </td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">
                  K: Inferences drawn from other personal information.
                </td>
                <td className="border-b px-4 py-2">
                  This category includes profiles reflecting on a person's
                  preferences, characteristics, psychological trends,
                  predispositions, behavior, attitudes, intelligence, abilities,
                  and aptitudes.
                </td>
                <td className="border-b px-4 py-2">Yes</td>
                <td className="border-b px-4 py-2">
                  Standard Business Services; Product and services
                  recommendations
                </td>
                <td className="border-b px-4 py-2">
                  Data Analytics Providers; advertising networks
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2 className="mb-4 mt-8 text-2xl font-bold">Data Security</h2>
        <p className="mb-4">
          We have implemented reasonable measures designed to secure your
          personal information from threats to its confidentiality, integrity,
          and availability, such as accidental loss and unauthorized access,
          use, alteration, or disclosure. Any payment transactions will be
          encrypted using industry-standard technologies.
        </p>
        <p className="mb-4">
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Marketplace, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone. We urge you to be careful about giving out information in
          public areas of the Marketplace like message boards or affiliated
          social media sites. The information you share in public areas may be
          viewed by any user of the Marketplace.
        </p>
        <p className="mb-4">
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Marketplace. Any transmission of
          personal information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Marketplace.
        </p>

        <h2 className="mb-4 mt-8 text-2xl font-bold">
          Changes to Our Privacy Policy
        </h2>
        <p className="mb-4">
          Our business changes from time to time, and our Privacy Policy will
          change also. Unless stated otherwise, our current Privacy Policy
          applies to all information that we have about you and your account. We
          stand behind the promises we make and will never materially change our
          policies and practices to make them less protective of your
          information collected in the past without your consent. The date the
          Privacy Policy was last revised is identified at the top of the page.
          You are responsible for ensuring we have an up-to-date active and
          deliverable email address for you, and for periodically visiting our
          Marketplace and this Privacy Policy to check for any changes.
        </p>

        <h2 className="mb-4 mt-8 text-2xl font-bold">Contact Information</h2>
        <p className="mb-4">
          To ask questions or comment about this Privacy Policy and our privacy
          practices, contact us at{" "}
          <a
            href="mailto:mxlegal@medxsvc.com"
            className="text-blue-600 hover:underline"
          >
            mxlegal@medxsvc.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
