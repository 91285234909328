import axios from "axios";
import { useShowSessionExpiredDialog } from "../store";

const useRefreshTokenHook = () => {
  const { setSessionExpiredData } = useShowSessionExpiredDialog();
  const refresh = axios.create({
    baseURL: "",
  });

  refresh.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      setSessionExpiredData({ visible: true, action: "sessionExpired" });
      return Promise.reject(error);
    },
  );

  return {
    refresh,
  };
};

export default useRefreshTokenHook;
