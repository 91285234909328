import React, { useRef, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";

import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import LocationCard from "./LocationCard";
import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import AroundTheWorld from "../../iconComponents/AroundTheWorld";
import { useLocationStore, useStoreEntityIdFromUrl } from "../../store";

function LocationsExplore() {
  const navigate = useNavigate();
  const [locations, setLocations] = useState(null);
  const [location, setLocation] = useState(null);
  const [states, setStates] = useState();
  const [serviceLocationNames, setServiceLocationNames] = useState("");
  const [visibleEnable, setVisibleEnable] = useState("");
  const [serviceNames, setServiceNames] = useState("");
  const toast = useRef(null);
  let nameLocation = "";
  let addressLocation = {};
  let isEnabled = "";

  const {
    setLocationInfo,
    locationsInfo,
    locationsChangeBar,
    setLocationsChangeBar,
  } = useLocationStore();

  const toastFunction = (severity, summary, detail) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };
  const { setEntityId } = useStoreEntityIdFromUrl();
  const { userId, entityId, IdentifireId, userRoles } = useTokenData();
  const [visible, setVisible] = useState(false);
  const { http } = useAxios();
  const defaultValues = {
    name: "",
    registeredAgentAddress: {
      lineOne: "",
      lineTwo: "",
      state: "",
      city: "",
      zipCode: "",
    },
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const getLocations = async () => {
    const response = await http.get(
      `/Locations/GetLocationsByEntityId/${entityId}`,
    );
    reset();
    setLocationsChangeBar(false);
    setLocations(response.data);
  };

  useEffect(() => {
    setEntityId(IdentifireId);
    getLocations();
  }, []);

  useEffect(() => {
    reset({
      name: locationsInfo.name,
      registeredAgentAddress: locationsInfo.address,
    });
  }, [locationsChangeBar]);

  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  const acceptDelete = async (id) => {
    try {
      const res = await http.delete(`/Locations/DeleteEntityLocation/${id}`);
      getLocations();
      toastFunction("success", "Success", "Location Deleted Successfully");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.message) {
        const locationInServices = err.response.data.locationInServices || [];
        const serviceNames = locationInServices
          .map((service) => service.name)
          .join(", ");
        setServiceNames(serviceNames);
      }
      setVisible(true);
    }
  };
  const rejectDelete = () => {};

  const confirmDelete = (locationIdFromCard) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptDelete(locationIdFromCard),
      reject: rejectDelete,
    });
  };
  //********************************** */

  const footerEnableContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => setVisibleEnable(false)}
        autoFocus
      />
    </div>
  );

  const confirmToggle = async (location) => {
    try {
      const res = await http.put(
        `/Locations/UpdateEnableOfEntityLocation/${location.id}?isForced=false`,
      );
      getLocations();
      toastFunction("success", "Success", res.data.message);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.message) {
        const locationInServices = err.response.data.locationInServices || [];
        const serviceNames = locationInServices
          .map((service) => service.name)
          .join(", ");
        setServiceLocationNames(serviceNames);

        confirmDialog({
          message: (
            <div className="flex max-w-3xl items-center gap-2">
              <i
                className="pi pi pi-info-circle text-red-300"
                style={{ fontSize: "2rem" }}
              ></i>
              <p>
                This location is currently associated with the following
                services:
                {serviceNames && (
                  <span className="block pb-1 pt-1 font-semibold">
                    {" "}
                    {serviceNames}{" "}
                  </span>
                )}
                Please ensure all references to this location are removed from
                your services before proceeding with disabling.
              </p>
            </div>
          ),
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => forceConfirmToggle(location),
          reject: () => rejectToggle(),
        });
      }
    }
  };

  const rejectToggle = () => {};

  const toggleStatus = (location) => {
    confirmDialog({
      message: "Are you sure you want to proceed with this action?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        confirmToggle(location);
      },
      reject: () => {
        rejectToggle();
      },
    });
  };

  const forceConfirmToggle = async (location) => {
    try {
      const res = await http.put(
        `/Locations/UpdateEnableOfEntityLocation/${location.id}?isForced=true`,
      );
      getLocations();
      toastFunction("success", "Success", res.data.message);
    } catch (err) {
      console.error(err);
      toastFunction("error", "Error", err.data.message);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        header="Oops! It looks like there's an issue."
        visible={visible}
        onHide={() => setVisible(false)}
        footer={footerContent}
        className="w-2/6"
      >
        <div className="flex items-center gap-2">
          <i
            className="pi pi pi-info-circle text-red-300"
            style={{ fontSize: "2rem" }}
          ></i>
          <p>
            This location is currently associated with the following services:
            {serviceNames && (
              <p className="pb-1 pt-1 font-semibold"> {serviceNames} </p>
            )}
            Please ensure all references to this location are removed from your
            services before proceeding with deletion.
          </p>
        </div>
      </Dialog>

      <Dialog
        header="Oops! It looks like there's an issue."
        visible={visibleEnable}
        onHide={() => setVisibleEnable(false)}
        footer={footerEnableContent}
        className="w-2/6"
      >
        <div className="flex items-center gap-2">
          <i
            className="pi pi pi-info-circle text-red-300"
            style={{ fontSize: "2rem" }}
          ></i>
          <p>
            This location is currently associated with the following services:
            {serviceLocationNames && (
              <p className="pb-1 pt-1 font-semibold">
                {" "}
                {serviceLocationNames}{" "}
              </p>
            )}
            Please ensure all references to this location are removed from your
            services before proceeding with disabling.
          </p>
        </div>
      </Dialog>

      {userRoles ? null : (
        <div className="grid grid-cols-2 rounded bg-white p-5">
          <div>
            <Skeleton className="mb-2 p-2" />
            <Skeleton className="mb-4 p-2" width="50%" />
            <Skeleton className="mb-1 p-2" />
            <Skeleton className="mb-1 p-2" />
            <Skeleton className="mb-1 p-2" />
            <Skeleton className="mb-3 p-2" width="50%" />
            <Skeleton className="p-5" width="50%" />
          </div>
          <div>
            <Skeleton size="50%" className="ml-auto mr-2 mt-2" />
          </div>
        </div>
      )}
      {userRoles === "Admin" ? (
        <div className="flex max-w-full justify-between rounded-md bg-white p-4">
          <div className="w-full text-dark-purple sm:max-w-[50%]">
            <h3 className="mb-2 text-xl font-bold">
              Expand your impact by setting up your locations
            </h3>
            <p>
              Prioritize creating and setting up medical practice locations as
              it significantly impacts the accessibility for your services,
              ensuring they can easily find and access your services.
            </p>
            <Button
              onClick={() => {
                setLocationInfo(false);
                navigate(`/rendering/mylocations/locationForm/`);
              }}
              className="mt-5"
            >
              <i className="pi pi-plus mr-2"></i> Set up new location
            </Button>
          </div>
          <AroundTheWorld />
        </div>
      ) : null}

      <div className="mt-2 rounded-lg bg-white px-4 py-6">
        <h3 className="text-xl font-bold text-dark-purple">Locations</h3>
        <div className="flex flex-col gap-3 py-4">
          {locations
            ? null
            : Array(5)
                .fill(0)
                .map((n, i) => (
                  <div className="rounded-lg border bg-white p-4" key={i}>
                    <Skeleton className="mb-2 p-4" width={"35%"} />
                    <Skeleton className="p-4" />
                  </div>
                ))}
          {locations?.map((location, i) => (
            <div key={i} className="">
              <LocationCard
                toastToggle={toastFunction}
                location={location}
                pessimisticUpdate={getLocations}
                setLocation={setLocation}
                confirmDelete={confirmDelete}
                handleEyeToggle={toggleStatus}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default LocationsExplore;
