import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

export default function AssociatedServicesDialog({ id }) {
  const [services, setServices] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const { http } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    fetchServices(id);
  }, []);

  const fetchServices = async (id) => {
    try {
      const response = await http.get(
        `/BillableItems/GetAttachedServices/${id}`,
      );
      setServices(response.data);
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditServie = (id) => {
    navigate(`/Service/MyServices/EditService/${id}/${false}`);
  };
  return (
    <div className="flex flex-col gap-3 p-3">
      {isFetching ? (
        <div className="flex items-center justify-center">
          <ProgressSpinner />
        </div>
      ) : services.length > 0 ? (
        services.map((service) => (
          <div
            key={service.id}
            className="grid grid-cols-12 rounded border p-3 "
          >
            <div className="col-span-10 flex flex-col ">
              <h1 className="font-bold">
                {service.name}{" "}
                {service.isTemplate && (
                  <span className="text-sm font-normal italic text-gray-500">
                    (Template)
                  </span>
                )}
              </h1>
              <p className="text-sm italic text-gray-600">
                {service.description}
              </p>
            </div>
            <div className="col-span-2 flex items-center justify-center">
              <Tooltip target=".service-action" className="max-w-sm" />
              <i
                onClick={() => handleEditServie(service.id)}
                className="pi pi-pencil service-action cursor-pointer rounded-full border-2 p-2 text-purple-700"
                data-pr-tooltip="Edit Service"
                data-pr-position="top"
              />
            </div>
          </div>
        ))
      ) : (
        <div>This Billable Item is not attached to any service</div>
      )}
    </div>
  );
}
