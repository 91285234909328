import React, { useEffect, useState } from "react";

import { Tooltip } from "primereact/tooltip";

import Pagination from "../Pagination";
import useAxios from "../../hooks/useAxios";
import ReusableTable from "../ReusableTable";
import useHandleResize from "../../hooks/useHandleResize";
import ReusableCardList from "../ReusableCardList/ReusableCardList";
import { FilterMatchMode } from "primereact/api";
import ReusableFilter from "../ReusableFilter/ReusableFilter";
import { blockSpecialCharsPattern } from "../utility/Utils";

const REQUESTED_DATA_SIZE = 5;

const ReusableDataList = ({
  dataLink,
  fetchMethod,
  payload,
  columns,
  actionTemplates,
  filterTemplate,
  refetch,
  sendSearchData,
  searchValue,
  fromFront = false,
  RemitsCheck = false,
  headerAction,
  title,
  selectionRowFunction = false,
  onSelectionChange,
  disableSearch = false,
  searchTitle,
  dropdownValues = false,
  setTableDataForCheck = false,
  onSearch,
  addNew,
  selectedRows,
  cardFilters,
  scrollable,
  scrollHeight,
  emptyMessage,
  isRowSelectable,
  rowClassName,
  rightToolbarTemplate,
  setSelectedRows,
}) => {
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const { isMobile } = useHandleResize();
  const [refetchPaginator, setRefetchPaginator] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { http } = useAxios();

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, [refetch]);

  useEffect(() => {
    if (!refetchPaginator) return;
    fetchData(page);
    setRefetchPaginator(false);
  }, [refetchPaginator]);

  // ***************search ************
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  // ************************
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const url = fromFront
        ? dataLink
        : dataLink + `&Page=${page + 1 || 1}&PageSize=${REQUESTED_DATA_SIZE}`;
      const response = fetchMethod
        ? fetchMethod === "put" && (await http.put(url, payload))
        : await http.get(url);
      if (response.data.length <= 0 && page > 0) {
        const previousPage = page - 1;
        setFirst(previousPage * REQUESTED_DATA_SIZE);
        setPage(previousPage);
        setRefetchPaginator(true);
      } else {
        setTableData(response.data);
        setTableDataForCheck && setTableDataForCheck(response.data);
        setTotalPages(response.data[0]?.count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = async (event) => {
    setFirst(event.first);
    setPage(event.page);
    if (first !== event.first) {
      setRefetchPaginator(true);
    }
  };

  return (
    <>
      <Tooltip
        showDelay={700}
        pt={{ text: { style: { whiteSpace: "nowrap" } } }}
        target=".custom-target-icon"
      />
      {isMobile ? (
        <div className="w-full rounded-lg bg-light-text/10 p-2 py-4">
          <h1 className="font-bold">{title}</h1>
          <ReusableFilter
            globalFilterValue={globalFilterValue}
            onGlobalFilterChange={onGlobalFilterChange}
            fromFront={fromFront}
            searchTitle={searchTitle}
            searchValue={searchValue}
            sendSearchData={sendSearchData}
            filterTemplate={filterTemplate}
            addNew={addNew}
            onSearch={onSearch}
            keyFilter={
              dropdownValues?.selectedValue === "OrderTrackingNumber@=*"
                ? "int"
                : blockSpecialCharsPattern
            }
          />
          <ReusableCardList
            tableData={tableData}
            actionTemplates={actionTemplates}
            globalFilterValue={globalFilterValue}
            rightToolbarTemplate={rightToolbarTemplate}
            setSelectedRemits={setSelectedRows}
            RemitsCheck={RemitsCheck}
            selectedRows={selectedRows}
            cardFilters={cardFilters}
          />
        </div>
      ) : (
        <ReusableTable
          searchTitle={searchTitle}
          tableData={tableData}
          sendSearchData={sendSearchData}
          searchValue={searchValue}
          columns={columns}
          actionTemplates={actionTemplates}
          fromFront={fromFront}
          RemitsCheck={RemitsCheck}
          title={title}
          headerAction={headerAction}
          selectionRowFunction={selectionRowFunction}
          onSelectionChange={onSelectionChange}
          disableSearch={disableSearch}
          dropdownValues={dropdownValues}
          globalFilterValue={globalFilterValue}
          onGlobalFilterChange={onGlobalFilterChange}
          filters={filters}
          scrollable={scrollable}
          scrollHeight={scrollHeight}
          emptyMessage={emptyMessage}
          selectedRows={selectedRows}
          setSelectedRemits={setSelectedRows}
          isLoading={isLoading}
          isRowSelectable={isRowSelectable}
          rowClassName={rowClassName}
          rightToolbarTemplate={rightToolbarTemplate}
        />
      )}
      {!fromFront && tableData.length > 0 && (
        <Pagination
          currentPage={first}
          totalPages={totalPages}
          size={REQUESTED_DATA_SIZE}
          onPageChange={handlePageChange}
          template={
            isMobile
              ? { layout: "PrevPageLink CurrentPageReport NextPageLink" }
              : {
                  layout:
                    "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                }
          }
        />
      )}
    </>
  );
};

export default ReusableDataList;
