import React, { useEffect, useRef, useState } from "react";

import { Navigate, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import SingleQuestionForm from "./SingleQuestionForm";
import useHandleResize from "../../hooks/useHandleResize";
import ReusableDataList from "../ReusableDataList/ReusableDataList";
import useDelayedInputChange from "../../hooks/useDelayedInputChange";

function ManageServicesTemplate() {
  const { http } = useAxios();
  const toast = useRef(null);
  const navigate = useNavigate();
  const { entityId } = useTokenData();
  const [checked, setChecked] = useState(true);
  const [refetch, setRefetch] = useState();
  const [questionName, setQuestionName] = useState();
  const [editVisible, setEditVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataRow, setDataRow] = useState();
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );
  const { isMobile } = useHandleResize();

  //edit question name
  const EditQuestionName = async (dataRow) => {
    setIsLoading(true);
    try {
      const response = await http.put(`/Questions/EditQuestion/${dataRow.id}`, {
        question: questionName,
        isEnabled: dataRow.isEnabled,
      });
      setQuestionName("");
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      setRefetch((e) => !refetch);
    } catch (error) {
      console.error("Error completing transaction:", error);
    }
    setIsLoading(false);
  };
  const handleDelete = async (rowData) => {
    setIsLoading(true);
    try {
      const response = await http.delete(`/Services/${rowData.id}`);
      setQuestionName("");
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      setRefetch((e) => !refetch);
    } catch (error) {
      console.error("Error completing transaction:", error);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail:
          error.response.data.message ||
          "Something went wrong. Please try again later",
        life: 4000,
      });
    }
    setIsLoading(false);
  };

  const confirmDelete = (rowData) => {
    confirmDialog({
      message: "Are you sure to delete this service?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        handleDelete(rowData);
      },
    });
  };
  const dialogEditQuestion = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => {
          setEditVisible(false);
          setQuestionName("");
        }}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          EditQuestionName(dataRow);
          setEditVisible(false);
        }}
        autoFocus
      />
    </div>
  );
  const EditEnabled = async (rowData) => {
    setIsLoading(true);
    const data = new FormData();
    data.append("isEnabled", true);
    try {
      const response = await http.put(
        `/Services/EditEnableService/${
          rowData.id
        }?isEnabled=${!rowData.isEnabled}`,
      );
      setQuestionName("");
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message || "Status Updated Successfully",
        life: 4000,
      });
      setRefetch((e) => !refetch);
    } catch (error) {
      console.error("Error completing transaction:", error);
    }
    setIsLoading(false);
  };

  const confirmEnable = (rowData) => {
    confirmDialog({
      message:
        rowData.isEnabled === true
          ? "Are you sure to disable this service?"
          : "Are you sure to enable this service?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        EditEnabled(rowData);
      },
    });
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex items-center gap-5">
        <i
          className={`custom-target-icon pi pi-pencil cursor-pointer text-indigo`}
          data-pr-tooltip="Edit Service"
          data-pr-position="top"
          onClick={() => {
            navigate(`/Service/MyServices/EditService/${rowData.id}/${true}`, {
              state: { getFromTemplate: true },
            });
          }}
        ></i>
        <InputSwitch
          tooltip={rowData.isEnabled ? "Disable" : "Enable"}
          tooltipOptions={{ position: "top" }}
          checked={rowData.isEnabled}
          onChange={() => {
            confirmEnable(rowData);
          }}
          key={`disable${rowData.id}`}
        />
        <i
          className={`custom-target-icon pi pi-trash cursor-pointer text-red-500`}
          data-pr-tooltip="delete"
          data-pr-position="top"
          onClick={() => {
            confirmDelete(rowData);
          }}
        ></i>
      </div>
    );
  };
  const serviceTypeTemplate = (rowData) => {
    return (
      <>
        {rowData.serviceType === 1 ? (
          <p>Scheduled Appointment Service</p>
        ) : (
          <p>Walk-In Service</p>
        )}
      </>
    );
  };
  const columns = [
    { name: "Service Name", value: "name" },
    {
      name: "Service Type",
      value: "serviceType",
      template: serviceTypeTemplate,
    },
    // { name: "Last Modified", value: "" },
  ];

  const cardTemplate = (row) => {
    return (
      <>
        <p className="font-semibold">{row.name}</p>

        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <InputSwitch
              checked={row.isEnabled}
              onChange={(e) => {
                confirmEnable(row);
              }}
            />
          </div>
          <div className="flex items-center gap-2">
            <i
              onClick={() => {
                navigate(`/Service/MyServices/EditService/${row.id}/${true}`);
              }}
              className="pi pi-pencil cursor-pointer rounded-full border-2 p-2 text-purple-700"
            />
            <i
              onClick={() => {
                confirmDelete(row);
              }}
              className="pi pi-trash cursor-pointer rounded-full border-2 p-2 text-red-700"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      {isLoading ? (
        <div className="absolute z-10 flex h-full w-[84%] items-center justify-center overflow-hidden bg-white bg-opacity-60">
          <ProgressSpinner
            strokeWidth={4}
            style={{ width: "40px", height: "40px" }}
          />
        </div>
      ) : null}
      <div className="flex flex-col gap-4">
        <h2 className="font-philosopher text-title font-extrabold capitalize text-dark-purple">
          Service Templates
        </h2>
        <div className="flex flex-col justify-between gap-2 sm:flex-row">
          <div className="basis-2/3 font-semibold text-gray-600">
            Create and manage service templates to streamline the creation of
            new services. Use templates to ensure consistency and save time.
          </div>
          <div className="flex gap-2">
            <Button
              className="hidden h-12 w-12 gap-2 whitespace-nowrap p-4 font-inter sm:flex sm:h-auto sm:w-auto"
              onClick={() =>
                navigate("/Service/MyServices/createNewServiceTemplate")
              }
            >
              <i className="pi pi-plus" />
              <span>New service template</span>
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        blockScroll
        draggable={false}
        header="Enter New Question"
        visible={editVisible}
        style={{ width: "30vw" }}
        footer={dialogEditQuestion}
        onHide={() => setEditVisible(false)}
      >
        <SingleQuestionForm />
      </Dialog>
      <div className="mt-4">
        <ReusableDataList
          sendSearchData={handleInputChange}
          searchValue={searchValue}
          searchTitle="search by service name"
          dataLink={`/Entities/GetEntityServices/${entityId}?Filters=isTemplate%3D%3Dtrue%2Cname%40%3D${searchValue}`}
          columns={columns}
          actionTemplates={[
            isMobile
              ? { template: cardTemplate }
              : { template: actionTemplate, header: "Actions" },
          ]}
          refetch={refetch}
          addNew={() =>
            navigate("/Service/MyServices/createNewServiceTemplate")
          }
        />
      </div>
    </>
  );
}

export default ManageServicesTemplate;
