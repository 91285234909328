import { useLayoutEffect } from "react";
import { Navigate, Outlet, useLocation, Link } from "react-router-dom";

import NavBar from "../NavBar";
import {
  useCurrentEntity,
  useExpireSystemAdmin,
  useRefreshTokenStore,
  useSwitchOrdering,
} from "../store";
import ReusableSidebar from "../ReusableSidebar";
import useTokenData from "../hooks/useTokenData";
import PracticeStatusNotification from "./services/ServiceStatusLabel";

function ProtectedRoute() {
  const location = useLocation();
  const { currentEntity } = useCurrentEntity();
  const { refreshToken } = useRefreshTokenStore();
  const { entityId, SystemAdministrator, UserJourneyStatus } = useTokenData();
  const { isOrderingMode } = useSwitchOrdering();
  const hideFooter = /^\/service\/myservices\/.+/.test(
    location.pathname.toLowerCase(),
  );

  useLayoutEffect(() => {
    const container = document.getElementById("protected-routes");
    container && container.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);
  const { isExpireAdmin } = useExpireSystemAdmin();

  return (
    <>
      {(refreshToken && entityId && UserJourneyStatus === "2") ||
      SystemAdministrator === "SystemAdministrator" ? (
        <>
          <div className="nav-bar">
            <NavBar />
          </div>
          <div className="w-100 flex rounded-tr-sm pt-20">
            <div className="hidden lg:block">
              <ReusableSidebar />
            </div>
            <div
              id="protected-routes"
              className="flex h-[calc(100vh-5rem)] w-full flex-col overflow-auto p-5 py-0"
            >
              <div className="mb-2 flex">
                <Link
                  to={
                    SystemAdministrator === "SystemAdministrator"
                      ? "admin/ourPractices"
                      : !isOrderingMode
                        ? "/Dashboard"
                        : "ordering/FindServices"
                  }
                >
                  <div className="flex items-center gap-2 font-bold text-[#343A40] hover:cursor-pointer hover:shadow-[0_2px_0_0_#343A40]">
                    <div>
                      <i className="pi pi-home"></i>
                    </div>
                    <div>Home</div>
                  </div>
                </Link>
              </div>
              {currentEntity?.isBannerRead === false && (
                <PracticeStatusNotification />
              )}
              <Outlet />

              {!hideFooter && (
                <div className="mt-auto pt-6">
                  <footer className="mx-2 mb-2 rounded-lg bg-white shadow">
                    <div className="mx-auto flex w-full max-w-screen-xl flex-col p-4 md:flex-row md:items-center md:justify-between">
                      <span className="text-center text-sm text-gray-500 dark:text-gray-400 md:text-left">
                        ©{" "}
                        <span id="currentYear">{new Date().getFullYear()}</span>{" "}
                        MedX™. All Rights Reserved.
                      </span>
                      <ul className="mt-3 flex flex-col items-center text-center text-sm font-medium text-gray-500 dark:text-gray-400 md:mt-0 md:flex-row md:text-left">
                        <li className="mb-2 md:mb-0 md:me-6">
                          <a
                            href="https://acceleratedhcs.com/"
                            target="_blank"
                            className="hover:underline"
                          >
                            <span className="pi pi-globe mr-1.5" />
                            About
                          </a>
                        </li>
                        <li className="mb-2 md:mb-0 md:me-6">
                          <a
                            href="/legal"
                            target="_blank"
                            className="hover:underline"
                          >
                            <span className="pi pi-list mr-1.5" />
                            Legal Notices and Terms
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://acceleratedhcs.com/#contact"
                            target="_blank"
                            className="hover:underline"
                          >
                            <span className="pi pi-phone mr-1.5" />
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </footer>
                </div>
              )}
            </div>
          </div>
        </>
      ) : refreshToken && UserJourneyStatus === "2" ? (
        <Navigate to="/chooseEntity/" replace />
      ) : SystemAdministrator === "SystemAdministrator" &&
        isExpireAdmin == true ? (
        <Navigate to={"/admin/expired"} replace />
      ) : (
        <Navigate
          to={location.pathname.includes("admin") ? "/admin/login" : "/login"}
          replace
        />
      )}
    </>
  );
}

export default ProtectedRoute;
