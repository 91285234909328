import React from "react";
import { Paginator } from "primereact/paginator";

function Pagination({ currentPage, totalPages, onPageChange, template, size }) {
  return (
    <Paginator
      first={currentPage}
      rows={size}
      totalRecords={totalPages}
      onPageChange={(e) => onPageChange(e)}
      template={template}
    />
  );
}

export default Pagination;
