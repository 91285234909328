import { useEffect, useRef, useState } from "react";

import { Steps } from "primereact/steps";
import { Button } from "primereact/button";

import useAxios from "./hooks/useAxios";
import RequestService from "./components/RequestService";
import ServiceCart from "./components/services/ServiceCart";
import { PatientInfo } from "./components/services/PatientInfo";
import { Divider } from "primereact/divider";

export default function TestService({ serviceId, serviceInfo, setVisible }) {
  const [patientInfo, setPatientInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmitCallback = (formData) => {
    setPatientInfo(formData);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const stepsLabel = [
    { label: "Patient Information" },
    { label: "Answer Questions" },
  ];
  const submitRef = useRef();
  const calculateCostRef = useRef();
  const [fileId, setFileId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [services, setServices] = useState({});
  const [generalInfo, setGeneralInfo] = useState({});
  const [orderData, setOrderData] = useState();
  const [cptBucket, setCptBucket] = useState([]);
  const [locationsBucket, setLocationsBucket] = useState([]);
  const [cost, setCost] = useState();
  const [orderDocs, setOrderDocs] = useState([]);

  const { http } = useAxios();

  const getData = async () => {
    let data;
    if (serviceInfo) {
      data = JSON.parse(serviceInfo);
    } else {
      const servicesData = await http.get(
        `/Services/GetServiceWizard/${serviceId}`,
      );
      data = JSON.parse(servicesData.data.serviceJson);
    }

    setOrderData(data);
    setQuestions(data.questions);
    setServices(data.services);
    setGeneralInfo(data.generalInfo);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="px-4">
      <Steps model={stepsLabel} activeIndex={activeIndex} />
      {activeIndex === 0 ? (
        <div className="flex h-full max-h-[70vh] flex-col gap-4">
          <div className="h-full overflow-y-auto p-4">
            <PatientInfo
              isTesting={true}
              submitRef={submitRef}
              fileId={fileId}
              setFileId={setFileId}
              setActiveIndex={setActiveIndex}
              orderData={orderData}
              onSubmitCallback={onSubmitCallback}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>
          <div className="flex justify-end">
            <Button
              label="Next"
              onClick={() => {
                submitRef.current.requestSubmit();
              }}
            />
          </div>
        </div>
      ) : activeIndex === 1 ? (
        <div className="flex h-full">
          <div className="flex max-h-[70vh] flex-1 flex-col gap-4">
            <div className="h-full overflow-y-auto pr-2">
              <RequestService
                isTesting={true}
                setCost={setCost}
                services={services}
                serviceId={serviceId}
                questions={questions}
                submitRef={submitRef}
                generalInfo={generalInfo}
                patientInfo={patientInfo}
                setOrderDocs={setOrderDocs}
                setCptBucket={setCptBucket}
                setActiveIndex={setActiveIndex}
                calculateCostRef={calculateCostRef}
                setLocationsBucket={setLocationsBucket}
              />
            </div>

            <div className="flex justify-end px-5">
              <Button
                outlined
                label="Calculate Cost"
                id="calc-cost"
                onClick={() => {
                  calculateCostRef.current.requestSubmit();
                }}
              />
            </div>
          </div>
          <Divider layout="vertical" />
          <div className="h-full max-h-[70vh] flex-1 overflow-y-auto pr-2">
            <ServiceCart
              cost={cost}
              isTesting={true}
              cptBucket={cptBucket}
              locationsBucket={locationsBucket}
              serviceId={serviceId}
              orderDocs={orderDocs}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
