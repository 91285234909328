import axios from "axios";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import { googleLogout } from "@react-oauth/google";

import useTokenData from "./useTokenData";
import {
  useExpireSystemAdmin,
  useRefreshTokenStore,
  useShowSessionExpiredDialog,
  useStoreEntityIdFromUrl,
  useTokenStore,
} from "../store";
import useImpersonating from "./useImpersonating";

const { setToken } = useTokenStore.getState();
const { setRefreshToken } = useRefreshTokenStore.getState();
const { setSessionExpiredData } = useShowSessionExpiredDialog.getState();

function useAxios() {
  const { exp, SystemAdministrator } = useTokenData();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const location = useLocation();
  const isAdmin = location.pathname.includes("admin");
  const { setIsExpireAdmin } = useExpireSystemAdmin();
  const { isSystemAdminImpersonating } = useImpersonating();
  const navigate = useNavigate();
  const currentRefreshToken = getRefreshToken();

  const microsoft = () => {
    sessionStorage.clear();
  };

  const unPersonate = async () => {
    try {
      const response = await http.post("/Entities/EndEntityImpersonation", {
        currentRefreshToken,
      });
      setToken(response.data.token);
      setRefreshToken(response.data.refreshToken);
      navigate(`/admin/ourPractices`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const logOut = async () => {
    const token = getAccessToken();
    const refreshToken = getRefreshToken();
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime < exp) {
      try {
        await axios.post(
          "/Authentication/LogOut",
          { refreshToken },
          {
            headers: { Authorization: `Bearer ${token}` },
            "Content-Type": "application/json",
          },
        );
      } catch (err) {}
    }

    microsoft();
    setToken(null);
    setRefreshToken(null);
    setEntityId(null);

    googleLogout();
    setIsExpireAdmin(null);
    navigate(
      isAdmin ||
        isSystemAdminImpersonating ||
        SystemAdministrator === "SystemAdministrator"
        ? "/admin/login"
        : "/login",
      { replace: true },
    );
  };

  return {
    http,
    logOut,
    unPersonate,
  };
}

const http = axios.create({
  baseURL: "",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

const refreshAndRetryQueue = [];
let isRefreshing = false;

http.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    const { exp } = getTokenData();
    config.headers.Authorization = "Bearer " + token;
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime + 20 > exp) {
      try {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            // Refresh the access token
            const { token, refreshToken } = await generateNewToken();

            setToken(token);
            setRefreshToken(refreshToken);
            // Update the request headers with the new access token
            config.headers["Authorization"] = `Bearer ${token}`;

            // Retry all requests in the queue with the new token
            refreshAndRetryQueue.forEach(async ({ config, resolve }) => {
              config.headers["Authorization"] = `Bearer ${token}`;
              if (config?.data && config?.data?.currentRefreshToken) {
                config.data.currentRefreshToken = refreshToken;
              }
              resolve(config);
            });

            // Clear the queue
            refreshAndRetryQueue.length = 0;
            if (config?.data && config?.data?.currentRefreshToken) {
              config.data.currentRefreshToken = refreshToken;
            }
            // Retry the original request
            return config;
          } catch (refreshError) {
            // Handle token refresh error
            // You can clear all storage and redirect the user to the login page
            setSessionExpiredData({ visible: true, action: "sessionExpired" });
            throw refreshError;
          } finally {
            isRefreshing = false;
          }
        }

        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({
            config,
            resolve,
            reject,
          });
        });
      } catch (refreshError) {
        throw refreshError;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      setSessionExpiredData({ visible: true, action: "sessionExpired" });
    } else if (error?.response?.status === 403) {
      setSessionExpiredData({ visible: true, action: "accessDenied" });
    }

    return Promise.reject(error);
  },
);

const generateNewToken = async () => {
  try {
    const refreshToken = getRefreshToken();
    const { entityId, impersonatedBy } = getTokenData();
    const response = await axios.post(
      `/Authentication/RefreshToken/${
        entityId || "00000000-0000-0000-0000-000000000000"
      }`,
      { refreshToken, impersonatedBy },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getRefreshToken = () => {
  return JSON.parse(localStorage.getItem("refreshToken")).state.refreshToken;
};

const getAccessToken = () => {
  const localStorageToken = JSON.parse(localStorage.getItem("token")).state
    .token;

  if (localStorageToken) return localStorageToken;

  const cookieData = Cookies.get("userData");
  let token = null;
  if (cookieData && typeof cookieData === "string") {
    const tokenRegex = /JwToken:'([^']+)/;
    const tokenMatch = cookieData.match(tokenRegex);
    token = tokenMatch ? tokenMatch[1] : null;
  }

  return token;
};

const getTokenData = () => {
  const token = getAccessToken();
  let base64Url;
  let base64;
  let jsonPayload;
  let deCodedJWT;
  if (token) {
    base64Url = token.split(".")[1];
    base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    deCodedJWT = JSON.parse(jsonPayload);
  }

  return deCodedJWT
    ? {
        entityId: deCodedJWT.EntityId,
        exp: deCodedJWT.exp,
        impersonatedBy: deCodedJWT.ImpersonatedBy,
      }
    : {};
};

export default useAxios;
