import useTokenData from "./useTokenData";

const useImpersonating = () => {
  const { impersonatedBy } = useTokenData();

  const isValidGUID = (guid) => {
    const guidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return guidPattern.test(guid);
  };

  const isSystemAdminImpersonating = isValidGUID(impersonatedBy);

  return { isSystemAdminImpersonating };
};

export default useImpersonating;
