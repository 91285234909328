import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useEffect, useState } from "react";

const stripePromise = loadStripe(
  "pk_test_51OI709G8XKIxl1QUWyGAfiaMSibkj0FgG1V4lcZHkfROz7T0CCFerGJX3VnAqu0jxgj3FpCuZlDXoT8aFU8LeO0Q00qCHFOm8G",
);

export default function EmbeddedStripeDialog({
  paymentLinkId,
  handleComplete,
}) {
  const [clientOptions, setClientOptions] = useState({
    clientSecret: "",
  });

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const fetchClientSecret = async () => {
    try {
      const res = await axios.get(`Payments/checkout/${paymentLinkId}`);
      setClientOptions({ clientSecret: res.data });
    } catch (error) {
      console.error("Error fetching client secret:", error);
    }
  };
  return (
    <div>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ ...clientOptions, onComplete: handleComplete }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
