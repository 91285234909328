import { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import DocumentsLibraryDialog from "./DocumentsLibraryDialog";

export default function DocumentsLibrary() {
  const [refetch, setRefetch] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [documentData, setDocumentData] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isDialogAppearing, setIsDialogAppearing] = useState(false);

  const toast = useRef();
  const { http } = useAxios();
  const { entityId } = useTokenData();

  useEffect(() => {
    getDocuments(entityId);
  }, [entityId, refetch]);

  const getDocuments = async (entityId) => {
    setIsFetching(true);
    try {
      const response = await http.get(
        `/EntityDocuments/GetAllEntityDocuments/${entityId}`,
      );
      setDocuments(response.data);
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await http.delete(`/EntityDocuments/${id}`);
      setRefetch((prevState) => !prevState);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Document Deleted Successfully",
        life: 4000,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: (
          <div>
            {error.response.data.message}
            <p className="font-bold">Check these services: </p>
            {error.response.data?.documentsInServices.map((service) => (
              <p key={service.id}>- {service.name}</p>
            ))}
          </div>
        ),
        life: 4000,
      });
    }
  };

  return (
    <div className="flex w-full flex-col gap-6 rounded-lg bg-white p-6">
      <Toast ref={toast} />
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        message={"Are you sure you want to delete this Document"}
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={() => {
          handleDelete(documentData.id);
        }}
        reject={() => {}}
      />
      <Dialog
        visible={isDialogAppearing}
        blockScroll
        header={documentData ? "Update Document" : "Upload Document"}
        onHide={() => {
          setIsDialogAppearing(false);
        }}
        className="w-11/12 md:w-[70vw]"
      >
        <DocumentsLibraryDialog
          toast={toast}
          setRefetch={setRefetch}
          documentData={documentData}
          setIsDialogAppearing={setIsDialogAppearing}
        />
      </Dialog>
      <div>
        <h1 className="mt-5 font-philosopher text-xl font-bold text-dark-purple">
          Manage Documents Library
        </h1>
        <p className="max-w-xl py-3 text-sm text-gray-500">
          This is where you keep all your documents. You can view, edit, and
          delete existing files, or add new ones by uploading files and filling
          in titles and descriptions.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div>
          <Button
            label="Add Document"
            onClick={() => {
              setDocumentData(null);
              setIsDialogAppearing(true);
            }}
          />
        </div>
        <div className="rounded-lg border border-slate-200 p-4">
          <div className=" w-full">
            <DataTable
              showGridlines
              scrollable
              loading={isFetching}
              pt={{
                loadingOverlay: {
                  style: {
                    backgroundColor: "#b9b5ff60",
                  },
                },
              }}
              scrollHeight="400px"
              className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
              value={documents}
              paginator
              emptyMessage={<p className="text-center">No results found</p>}
              rows={5}
            >
              <Column
                field="documentTitle"
                header="Title"
                align="center"
                alignHeader="center"
                style={{ width: "30%" }}
              />
              <Column
                field="internalDescription"
                header="Internal Description"
                align="center"
                alignHeader="center"
                style={{ width: "30%" }}
              />
              <Column
                field="externalDescription"
                header="External Description"
                align="center"
                alignHeader="center"
                style={{ width: "30%" }}
              />
              <Column
                header="Actions"
                align="center"
                alignHeader="center"
                style={{ width: "10%" }}
                body={(rowData, options) => (
                  <div className="flex items-center justify-center gap-4">
                    {options.rowIndex === 0 && <Tooltip target=".doc-action" />}
                    <i
                      className="doc-action pi pi-pencil cursor-pointer text-gray-500"
                      data-pr-tooltip="Edit Document"
                      data-pr-position="top"
                      onClick={() => {
                        setDocumentData(rowData);
                        setIsDialogAppearing(true);
                      }}
                    ></i>
                    <i
                      className="doc-action pi pi-eye cursor-pointer text-gray-500"
                      data-pr-tooltip="View Document"
                      data-pr-position="top"
                      onClick={() => {
                        window.open(rowData.documentPath, "_blank");
                      }}
                    ></i>
                    <i
                      className="doc-action pi pi-trash cursor-pointer text-red-500"
                      data-pr-tooltip="Delete Document"
                      data-pr-position="top"
                      onClick={() => {
                        setDocumentData(rowData);
                        setShowConfirmDialog(true);
                      }}
                    ></i>
                  </div>
                )}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
